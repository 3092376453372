<main class="frontpage terms-and-conditions">
  <section class="banner w-100 linear-bg">
    <div class="container-fluid content w-100">
      <div class="row">
        <div class="col-12">
          <div class="banner-card d-flex justify-content-center align-items-center">
            <h2 class="title">Terms and Conditions</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- terms and conditions details -->
  <section class="terms-and-conditions-details">
    <div class="container d-flex justify-content-center">
      <div class="text-box">
        <p class="paraghraph">Last updated: October 13th, 2022</p>

        <p class="paraghraph">Please read these Terms of Service ("Terms", "Terms of Service") carefully, as they
          constitute a binding legal contract between you ("you" or "your" either an individual or a legal entity) and
          Rapidise Inc. ("Rapidise", "us", "we", or "our"). These Terms govern your access to and use of the web sites,
          media, platforms, forums, portals, and any other online services offered by Rapidise including without
          limitation the https://rapidise.io/ website and any media, software, programs, services, tools, features,
          databases, materials, content, features, products, platforms, forums, portals, or information available or
          sold on or through it or used in connection therewith (collectively, the "Services"). Your use of the Services
          is subject to these Terms, our Privacy Policy and our Community Guidelines.</p>

        <p class="paraghraph">Your access to and use of the Services is conditioned upon your acceptance of and
          compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the
          Services.</p>

        <p class="paraghraph">By accessing or using the Services, you agree to be bound by these Terms. If you disagree
          with any part of the terms, you do not have permission to access the Services.</p>

        <p class="paraghraph">We reserve the right, at our sole discretion, to amend, change, modify, revise or replace
          these Terms at any time by posting revised Terms on the Service.</p>

        <p class="paraghraph">By continuing to access or use our Services after any revisions become effective, you
          agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to
          use the Service. You are responsible for reviewing the Terms for any changes, so please check back here every
          time you use the Services.</p>

        <p class="title">Right to Use</p>
        <p class="paraghraph">Subject to these Terms, we grant to you a personal, nonexclusive, limited,
          non-transferable, non-assignable, non-sublicensable, limited, revocable license to access and use the Services
          in accordance with these Terms. You acknowledge that you are receiving licensed rights only. You may not
          directly or indirectly, or authorize any person or entity to: (a) reverse engineer, decompile, disassemble,
          re-engineer or otherwise create or attempt to create or permit, allow or assist others to create the source
          code of the Services; (b) create derivative works of the Services; (c) use the Services in whole or in part
          for any purpose except as expressly provided herein; or (d) disable or circumvent any access control or
          related device, process or procedure established with respect to the Services.</p>

        <p class="title">Accounts</p>
        <p class="paraghraph">When you create an account with us, you guarantee that you are above the age of 18, and
          that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete,
          or obsolete information may result in the immediate termination of your account on the Service.</p>
        <p class="paraghraph">You are responsible for maintaining the confidentiality of your account and password,
          including but not limited to the restriction of access to your computer and/or account. Your username and
          password may not be used by any other person. You agree to accept responsibility for any and all activities or
          actions that occur under your account and/or password, whether your password is with our Services or a
          third-party service. You must notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account and you must ensure that you exit from your account at the end of each
          session. We shall not be liable for any loss or damage arising from your failure to comply with any of these
          Terms.</p>

        <p class="paraghraph">You may not use as a username the name of another person or entity or that is not lawfully
          available for use, a name or trademark that is subject to any rights of another person or entity other than
          you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or
          obscene. Your use of the Services or any content accessed through the Services must comply with all applicable
          laws, regulations and ordinances.</p>

        <p class="paraghraph">By providing us with your email address and other contact information, you consent to our
          using such contact information to send you Services-related notices, including any notices required by law, in
          lieu of communication by postal mail. We may also use your email address or phone number to send you other
          messages, such as changes to features of the Services and other information about our organization.</p>

        <p class="title">User Content</p>
        <p class="paraghraph">Some areas of the Services allow users to submit, post, display, provide, upload, or
          otherwise make available content such as profile information, contact information, photos, user forum
          features, comments, questions, and other content or information (any such materials a user submits, posts,
          displays, provides, uploads or otherwise makes available on the Services is referred to as "User Content").
          User Content may be viewable by other users of the Services and through third-party websites. As such, any
          User Content may be treated as non-confidential and non-proprietary. All User Content publicly posted or
          privately transmitted through the Services is the sole responsibility of the person from whom such User
          Content originated, and you access all such information and User Content at your own risk.</p>

        <p class="paraghraph">We claim no ownership rights over User Content created by you. The User Content you create
          remains yours. However, you understand that if portions of the Services allow other users to view, edit,
          share, and/or otherwise interact with your User Content, by providing or sharing User Content through the
          Services, you agree to allow others to view, edit, share, and/or interact with your User Content in accordance
          with your settings in the Services and these Terms. We have the right (but not the obligation) in our sole
          discretion to remove any User Content that is on or is shared via the Services.</p>

        <p class="paraghraph">By submitting, posting, displaying, providing, uploading or otherwise making available any
          User Content on or through the Service, you expressly grant, and you represent and warrant that you have all
          rights necessary to grant, to us a royalty-free, sublicensable, transferable, perpetual, irrevocable,
          non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit,
          translate, distribute, syndicate, publicly perform, publicly display, and make derivative works of all such
          User Content and your name, voice, and/or likeness as contained in your User Content, in whole or in part, and
          in any form, media or technology, whether now known or hereafter developed, for use in connection with the
          Services and our (and our successors' and affiliates') business, including without limitation for promoting
          and redistributing part or all of the Services (and derivative works thereof) in any media formats and through
          any media channels. You also hereby grant each user of the Services a non-exclusive right to access your User
          Content through the Services, and to use, reproduce, distribute, display and perform such User Content as
          permitted through the functionality of the Services and under these Terms. You waive all moral rights in your
          User Content, and you warrant that moral rights have not otherwise been asserted in your User Content. You
          will not be entitled to any compensation from us if we use your User Content or if your name, likeness, or
          voice is used or conveyed in connection with the Services.</p>

        <p class="paraghraph">In connection with your User Content, you affirm, represent and warrant the following: (a)
          you have the written consent of each and every identifiable natural person in the User Content, if any, to use
          such person's name or likeness in the manner contemplated by the Services and these Terms, and each such
          person has released you from any liability that may arise in relation to such use; (b) you have obtained and
          are solely responsible for obtaining all consents as may be required by law to post any User Content relating
          to third parties; and (c) your User Content and our use thereof as contemplated by these Terms and the
          Services will not violate any law or infringe any rights of any third party, including but not limited to any
          patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress
          and service mark rights, goodwill, trade secret rights and other intellectual property rights under the laws
          of any state, country, territory or other jurisdictions ("Intellectual Property Rights") and privacy rights.
        </p>

        <p class="paraghraph">To the best of your knowledge, all your User Content and other information that you
          provide to us is truthful and accurate.</p>

        <p class="paraghraph">We have the right, in our sole and absolute discretion, (a) to edit, redact, or otherwise
          change any User Content; (b) to re-categorize any User Content to place them in more appropriate locations on
          the Services; and (c) to pre-screen or delete any User Content at any time and for any reason, without notice.
        </p>

        <p class="paraghraph">We take no responsibility and assume no liability for any User Content, including its
          accuracy, validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or safety,
          or any intellectual property rights therein, that you or any other user or third-party posts, sends, or
          otherwise makes available via the Services. You shall be solely responsible for your User Content and the
          consequences of posting, publishing it, sharing it, or otherwise making it available via the Services, and you
          agree that we are only acting as a passive conduit for your online distribution and publication of your User
          Content. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable,
          inappropriate for children, or otherwise unsuited to your purpose, and you agree that we shall not be liable
          for any damages you allege to incur as a result of or relating to any User Content.</p>

        <p class="paraghraph">Although we reserve the right to review all content and User Content that appears on the
          Services and to remove any content or User Content that violates these Terms, our Community Guidelines or the
          law, we do not necessarily review all of it. We also cannot guarantee the identity of any users with whom you
          interact in using the Services and are not responsible for which users gain access to the Services.</p>

        <p class="title">Interactions with Other Users</p>
        <p class="paraghraph">If the Services permits interaction with other users, you are solely responsible for such
          interactions. We reserve the right, but have no obligation, to monitor disputes between you and other users.
          We shall have no liability for your interactions with other users, or for any user's action or inaction.</p>

        <p class="title">Moderators</p>
        <p class="paraghraph">Some areas of the Services may allow you and/or other users to serve as unofficial,
          voluntary moderators of User Content on the Services ("Moderators"). We are not responsible for actions taken
          by Moderators. We reserve the right to revoke or limit your ability to serve as a Moderator at any time and
          for any or no reason, including for breach of these Terms.</p>

        <p class="paraghraph">If you elect to become a Moderator you agree to (a) comply with the Moderation Handbook;
          (b) take appropriate action to remove or restrict, as applicable, User Content that violates these Terms, the
          Community Guidelines or the Moderation Handbook and escalate issues in accordance with such terms and
          policies; (c) not perform any moderation activities in return for any form of compensation from a third party;
          (d) protect the confidentiality of any non-public information you access or receive in connection with your
          performance as a Moderator; (e) not represent that you are authorized to act on behalf of us and (f) not enter
          into any agreements with third parties on behalf of us.</p>

        <p class="paraghraph">We reserve the right, but have no obligation, to overturn any action or decision of a
          Moderator if we, in our sole discretion, believe that such action or decision is not in the interest of us or
          the Rapidise community.</p>

        <p class="title">Acceptable Use</p>
        <p class="paraghraph">You agree not to engage in any of the following prohibited activities in connection with
          your use of the Services and/or any features or functions therein: (a) copying, distributing, or disclosing
          any part of the Services in any medium, including without limitation by any automated or non-automated
          "scraping"; (b) using any automated system, including without limitation "robots," "spiders," "offline
          readers," etc., to access the Services in a manner that sends more request messages to our servers than a
          human can reasonably produce in the same period of time by using a conventional online web browser; (c)
          transmitting spam, chain letters, or other unsolicited email; (d) attempting to interfere with, compromise the
          system integrity or security or decipher any transmissions to or from the servers running the Services; (e)
          taking any action that imposes, or may impose at our sole discretion an unreasonable or disproportionately
          large load on our infrastructure; (f) uploading or transmitting invalid data, viruses, worms, or other
          software agents through the Services; (g) collecting or harvesting any personally identifiable information,
          including account names, from the Services; (h) impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity; (i)
          interfering with the proper working of the Services; (j) accessing any content on the Services through any
          technology or means other than those provided or authorized by the Services; or (k) bypassing the measures we
          may use to prevent or restrict access to the Services, including without limitation features that prevent or
          restrict use or copying of any content or enforce limitations on use of the Services or the content therein.
          You agree not to post User Content that: (a) may create a risk of harm, loss, physical or mental injury,
          emotional distress, death, disability, disfigurement, or physical or mental illness to you, to any other
          person, or to any animal; (b) may create a risk of any other loss or damage to any person or property; (c)
          seeks to harm or exploit children by exposing them to inappropriate content, asking for personally
          identifiable details or otherwise; (d) may constitute or contribute to a crime or tort; (e) contains any
          information or content that we deem to be unlawful, harmful, abusive, racially or ethnically offensive,
          defamatory, infringing, invasive of personal privacy or publicity rights, harassing, humiliating to other
          people (publicly or otherwise), libelous, threatening, profane, obscene, pornographic, or otherwise
          objectionable; (f) contains any information or content that is illegal (including, without limitation, the
          disclosure of insider information under securities law or of another party's trade secrets); (g) contains any
          information or content that you do not have a right to make available under any law or under contractual or
          fiduciary relationships; (h) contains information the disparages, tarnishes or otherwise harms, in our
          opinion, us and/or the Services or (i) contains any information or content that you know is not correct and
          current. We reserve the right, but are not obligated, to reject and/or remove any User Content that we
          believe, in our sole discretion, violates any of these provisions.</p>

        <p class="title">Changes to Services</p>
        <p class="paraghraph">Our Services are continuously evolving and improving. We may add or remove features,
          products, or functionalities, and we may also suspend or stop the Services altogether, in whole or in part. We
          may impose limits on certain features, or restrict access in certain ways. We may take any of these actions at
          any time, and we may not provide you with prior notice when we do so.</p>

        <p class="title">Social Media</p>
        <p class="paraghraph">As part of the Services, you may be able to link your account with other accounts you have
          with third-party service providers (each a "Third-Party Provider Account") by: (a) providing your Third-Party
          Provider Account login information through the Services; or (b) allowing us to access your Third-Party
          Provider Account. You represent and warrant that you are entitled to disclose your Third-Party Provider
          Account login information to us and/or grant us access to your Third-Party Provider Account, without breach by
          you of any of the terms and conditions that govern your use of the applicable Third-Party Provider Account,
          and without obligating us to pay any fees or making us subject to any usage limitations imposed by the
          third-party service provider of the Third-Party Provider Account. By granting us access to any Third-Party
          Provider Accounts, you acknowledge and agree that (i) we may access, make available, and store (if applicable)
          any content that you have provided to and stored in your Third-Party Provider Account (the "Social Media
          Content") so that it is available on and through the Services via your account, including without limitation
          any friend or connections lists and (ii) we may submit to and receive from your Third-Party Provider Account
          additional information to the extent you are notified when you link your account with the Third-Party Provider
          Account. Depending on the Third-Party Provider Accounts you choose and subject to the privacy settings that
          you have set in such Third-Party Provider Accounts, personally identifiable information that you post to your
          Third-Party Provider Accounts may be available on and through your account on the Services. Please note that
          if a Third-Party Provider Account or associated service becomes unavailable or our access to such Third Party
          Provider Account is terminated by the third-party service provider, then Social Media Content may no longer be
          available on and through the Services. You will have the ability to disable the connection between your
          account on the Services and your Third-Party Provider Accounts at any time. Please Note That Your Relationship
          With The Third-Party Service Providers Associated With Your Third-Party Provider Accounts Is Governed Solely
          By Your Agreement(S) With Such Third-Party Service Providers. We make no effort to review any Social Media
          Content for any purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are
          not responsible for any Social Media Content. You acknowledge and agree that we may access your email address
          book associated with a Third-Party Provider Account and your contacts list stored on your mobile device or
          tablet computer solely for purposes of identifying and informing you of those contacts who have also
          registered to use the Services. You can deactivate the connection between the Services and your Third-Party
          Provider Account by contacting us using the contact information below or through your account settings (if
          applicable).</p>

        <p class="title">Intellectual Property</p>
        <p class="paraghraph">You acknowledge that any and all Intellectual Property Rights subsisting in or used in
          connection with the Services and their original content, features and functionality are and will remain the
          exclusive property of Rapidise, Inc. and its licensors and you shall not at any time (including after
          termination of the license granted herein) in any way dispute, challenge, or undertake any action directly or
          indirectly that would be inconsistent with the ownership thereof by us or our licensors. The Services are
          protected by copyright, trademark, and other laws of both the United States and foreign countries. Our
          trademarks and trade dress may not be used in connection with any product or service without the prior written
          consent of Rapidise Inc. If you provide to us any ideas, proposals, suggestions or other materials
          ("Feedback"), whether related to the Services or otherwise, you hereby acknowledge and agree that such
          Feedback is not confidential, and that your provision of such Feedback is gratuitous, unsolicited and without
          restriction, and does not place us under any fiduciary or other obligation. We may use such Feedback without
          compensating you and without any restriction or obligation to you.</p>

        <p class="title">Links To Other Web Sites</p>
        <p class="paraghraph">Our Services may contain links to third party web sites or services that are not owned or
          controlled by Rapidise Inc.</p>

        <p class="paraghraph">Rapidise Inc. has no control over, and assumes no responsibility for the content, privacy
          policies, or practices of any third party web sites or services. We do not endorse and do not warrant the
          offerings of any of these entities/individuals or their websites.</p>

        <p class="paraghraph">You acknowledge and agree that Rapidise Inc. shall not be responsible or liable, directly
          or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or through any such third party web sites or
          services.</p>

        <p class="paraghraph">We strongly advise you to read the terms and conditions and privacy policies of any third
          party web sites or services that you visit.</p>

        <p class="title">Termination</p>
        <p class="paraghraph">We may terminate or suspend your account and bar access to the Services immediately,
          without prior notice or liability, under our sole discretion, for any reason whatsoever and without
          limitation, including but not limited to a breach of the Terms. If your account is canceled or terminated, we
          may permanently delete any User Content from our Services. If you wish to terminate your account, you may
          simply discontinue using the Service. All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation, ownership provisions, warranty
          disclaimers, indemnity and limitations of liability.</p>

        <p class="title">Indemnification</p>
        <p class="paraghraph">You agree, to the fullest extent permitted by applicable law, to defend, indemnify and
          hold harmless Rapidise Inc. and its licensee and licensors, and their employees, contractors, agents, officers
          and directors, from and against any and all claims, actions, settlements, damages, obligations, losses,
          liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or
          arising out of or relating to (a) your use and access of the Service, by you or any person using your account
          and password, (b) your User Content, (c) your violation of the rights of a third party, including but not
          limited to intellectual property rights; (d) any overt harmful act toward any other user of the Services with
          whom you connected via the Services or (e) a breach of these Terms. Notwithstanding the foregoing, we reserve
          the right, at your expense, to assume the exclusive defense and control of any matter for which you are
          required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We
          will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
          indemnification upon becoming aware of it.</p>

        <p class="title">Limitation Of Liability</p>
        <p class="paraghraph">Except To The Extent Required By Applicable Law, In No Event Shall Rapidise Inc., Nor Its
          Directors, Employees, Partners, Agents, Suppliers, Or Affiliates, Be Liable For Any (A) Indirect, Incidental,
          Special, Exemplary, Consequential Or Punitive Damages, Including Without Limitation, Loss Of Profits, Data,
          Use, Goodwill, Or Other Intangible Losses, Resulting From (I) Your Access To Or Use Of Or Inability To Access
          Or Use The Service; (Ii) Any Conduct Or Content Of Any Other Users Or Third Party On Or Through The Service;
          (Iii) Any Content Obtained From The Service; And (Iv) Unauthorized Access, Use Or Alteration Of Your
          Transmissions Or Content, Whether Based On Warranty, Contract, Tort (Including Negligence) Or Any Other Legal
          Theory, Whether Or Not We Have Been Informed Of The Possibility Of Such Damage, And Even If A Remedy Set Forth
          Herein Is Found To Have Failed Of Its Essential Purpose.</p>

        <p class="paraghraph">To The Maximum Extent Permitted Under Applicable Law, Our Maximum Liability To You
          Relating To The Services Or Arising Out Of Or In Any Way Connected To These Terms Shall Not Exceed One Hundred
          Dollars. The Existence Of One Or More Claims By You Will Not Increase Our Liability.</p>

        <p class="title">Disclaimer</p>
        <p class="paraghraph">Your Use Of The Services Is At Your Sole Risk. The Services Are Provided On An "As Is" And
          "As Available" Basis. The Services Are Provided Without Warranties Of Any Kind, Whether Express Or Implied,
          Including, But Not Limited To, Implied Warranties Of Merchantability, Fitness For A Particular Purpose,
          Non-Infringement Or Course Of Performance. Rapidise Inc., Its Subsidiaries, Affiliates, And Its Licensors Do
          Not Warrant That (A) The Services Will Function Uninterrupted, Secure Or Available At Any Particular Time Or
          Location; (B) Any Errors Or Defects Will Be Corrected; (C) The Services Are Free Of Viruses Or Other Harmful
          Components; Or (D) The Results Of Using The Services Will Meet Your Requirements.</p>

        <p class="title">Exclusions</p>
        <p class="paraghraph">Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
          limitation of liability for consequential or incidental damages, so the limitations above may not apply to
          you.</p>

        <p class="title">Governing Law</p>
        <p class="paraghraph">These Terms shall be governed and construed in accordance with the laws of Delaware,
          United States, without regard to its conflict of law provisions. You hereby irrevocably waive any and all
          rights to trial by jury in any legal proceeding arising out of or relating to these Terms. Our failure to
          enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
          provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these
          Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have had between us regarding the Service.</p>

        <p class="title">Copyright</p>
        <p class="paraghraph">The Digital Millennium Copyright Act (DMCA) provides copyright owners who believe that
          their rights under the United States copyright law have been infringed by acts of third parties over the
          Internet with ways to protect their rights. If you believe that your copyrighted work has been copied without
          your authorization and is available in the Services in a way that may constitute copyright infringement, you
          can provide notice of your claim to the designated agent listed below. For your notice to be effective, it
          must include the following information: (a) a physical or electronic signature of a person authorized to act
          on behalf of the owner of the intellectual property right that is allegedly infringed; (b) identification of
          the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Services are
          covered by the notification, a representative list of such works on the Services; (c) identification of the
          material that is claimed to be infringing or to be the subject of infringing activity and that is to be
          removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the
          material; (d) information reasonably sufficient to permit us to contact the complaining party, such as
          address, telephone number, and, if available, an email address at which the complaining party can be
          contacted; (e) a statement by you that you have a good-faith belief that the disputed use is not authorized by
          the copyright owner, its agent, or the law; and (f) a statement that the information in the notification is
          accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner
          of the exclusive right that is allegedly infringed.
          Our Designated Agent is: Brijesh Jagani - CEO Rapidise (DMCA@rapidise.io)</p>

        <p class="title">General</p>
        <p class="paraghraph">These Terms constitute the entire agreement between you and us pertaining to the subject
          matter hereof and supersede all prior or other arrangements, understandings, negotiations and discussions,
          whether oral or written. These Terms cannot be modified by you, and may only be modified by us as provided
          above.</p>

        <p class="paraghraph">You shall not assign or sublicense any of the rights and obligations under these Terms
          without the express prior written consent of us. Any assignment in contravention of this paragraph will be
          void.</p>

        <p class="paraghraph">All rights not expressly granted by us in these Terms are reserved by us and our
          licensors. Failure or neglect by us to enforce at any time any of the provisions hereof shall not be construed
          nor shall be deemed to be a waiver of our rights hereunder nor in any way affect the validity of the whole or
          any part of these Terms nor prejudice our rights to take subsequent action. The headings contained herein are
          for convenience only and are not intended to be part of or to affect the meaning or interpretation of any of
          the terms and conditions of these Terms. There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms or use of the Services.</p>

        <p class="paraghraph">You agree that these Terms will not be construed against us by virtue of having drafted
          them. You hereby waive any and all defenses you may have based on the electronic form of these Terms and the
          lack of signing by the parties hereto to execute these Terms.</p>

        <p class="paraghraph">In the event that any of these terms, conditions or provisions shall be determined by any
          court of competent jurisdiction to be invalid, unlawful or unenforceable to any extent, such term, condition
          or provision shall to that extent be severed from the remaining terms, conditions and provisions which shall
          continue to be valid to the fullest extent permitted by law.</p>

        <p class="paraghraph">A printed version of these Terms and of any notice given in electronic form will be
          admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent
          and subject to the same conditions as other business documents and records originally generated and maintained
          in printed form.</p>

        <p class="title">Contact Us</p>
        <p class="paraghraph">If you have any questions about these Terms, please contact us</p>

      </div>
    </div>
  </section>

  <app-front-footer></app-front-footer>
</main>
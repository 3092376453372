import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FrontHeaderComponent } from "./layouts/front-header/front-header.component";
import { FrontComponent } from "./front/front.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { JourneyComponent } from "./journey/journey.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FaqComponent } from "./faq/faq.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { DesignSystemComponent } from "./design-system/design-system.component";
import { SiteConstructionComponent } from "./site-construction/site-construction.component";
import { RequirementDetailsComponent } from './requirement-details/requirement-details.component';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
  {
    path: "",
    component: FrontHeaderComponent,
    canActivateChild: [MetaGuard],
    children: [
      { path: "", component: FrontComponent,data: {
        meta: {
          title: "Rapidise",
          description: "Rapidise.io is the world's first and only AI & Blockchain-enabled Talent & Technology as a Service (TTaaS) platform which accelerates a company’s product engineering by allowing users to quickly find, hire, and manage qualified talent and market-ready technology solutions from a single platform."
        }
      } },
      { path: "about", component: AboutUsComponent },
      { path: "journey", component: JourneyComponent },
      { path: "contact", component: ContactUsComponent },
      // { path: "faq", component: FaqComponent },
      { path: "privacy-policy", component: PrivacyPolicyComponent },
      { path: "terms-and-conditions", component: TermsAndConditionsComponent },
      // { path: "design-system", component: DesignSystemComponent },
    ],
  },
  // { path: "", component: SiteConstructionComponent },
  { path: "lease", loadChildren: () => import("./partner-onboarding/partner-onboarding.module").then((m) => m.PartnerOnboardingModule) },
  { path: "client-onboarding", loadChildren: () => import("./client-onboarding/client-onboarding.module").then((m) => m.ClientOnboardingModule) },
  { path: "requirement-details/:userId", component: RequirementDetailsComponent },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

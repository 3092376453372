import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MetaModule } from '@ngx-meta/core';


import { AppRoutingModule } from "./app-routing.module";

// package imports
import { environment } from "./../environments/environment";
import { MyInterceptor } from "./config/http.interceptor";
import { MaterialModule } from "./material.module";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgSelectModule } from "@ng-select/ng-select";
import {MatIconModule} from '@angular/material/icon';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { OverlayContainer } from "@angular/cdk/overlay";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

// component imports
import { AppComponent } from "./app.component";
import { MenuListItemComponent } from "./layouts/menu-list-item/menu-list-item.component";
import { FrontHeaderComponent } from "./layouts/front-header/front-header.component";
import { FrontFooterComponent } from "./layouts/front-footer/front-footer.component";
import { FrontComponent } from "./front/front.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { JourneyComponent } from "./journey/journey.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FaqComponent } from "./faq/faq.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { ScrollToTopComponent } from "./scroll-to-top/scroll-to-top.component";
import { DesignSystemComponent } from "./design-system/design-system.component";
import { RequirementDetailsComponent } from './requirement-details/requirement-details.component';

// global declarations
// Sentry.init({
//   dsn: environment.sentry.dsn,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["https://beta.rapidise.io"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 0.75,
// });

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsSize: 30,
  pbColor: "#5EC090",
  fgsColor: "#5EC090",
  overlayColor: "rgba(0, 0, 0, 0.1)",
  hasProgressBar: false,
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    MenuListItemComponent,
    FrontHeaderComponent,
    FrontFooterComponent,
    FrontComponent,
    AboutUsComponent,
    JourneyComponent,
    ContactUsComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ScrollToTopComponent,
    DesignSystemComponent,
    RequirementDetailsComponent,
  ],
  imports: [
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    CarouselModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    NgbModule,
    RecaptchaV3Module,
    MatIconModule,
    MetaModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.api_key,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add("solution-dark-theme");
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContactUsService {
  constructor(private http: HttpClient) {}

  ContactUs(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/common/v1/rapidise-contact`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as AOS from "aos";
import { environment } from "./../../environments/environment";

@Component({
  selector: "app-front",
  templateUrl: "./front.component.html",
})
export class FrontComponent implements OnInit {
  imagePath = environment.image_path;

  constructor(private route: ActivatedRoute) {}

  isLoad: boolean = false;

  ngOnInit(): void {
    // this.route.data.subscribe(v =>
    //     console.log(v.model,"789798789789",v)
    // );

    AOS.init();

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      this.isLoad = true;
    }, 50);
  }

  customOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ['<img src="' + this.imagePath + 'left-arrow-black.svg" alt="left">', '<img src="' + this.imagePath + 'right-arrow-black.svg" alt="right">'],
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      992: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };

  customOptionsTesti: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 40,
    navText: ['<img src="' + this.imagePath + 'nav-prev-green.svg" alt="left">', '<img src="' + this.imagePath + 'nav-next-green.svg" alt="right">'],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
}

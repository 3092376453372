import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, FormControl, Validators } from "@angular/forms";
import { ContactUsService } from "./contact-us.service";
import { EventEmitterService } from "../config/event-emitter.service";
import { NotificationService } from "../config/notification.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { environment } from "./../../environments/environment";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
})
export class ContactUsComponent implements OnInit {
  imagePath = environment.image_path;

  public CurrentYear = new Date().getFullYear();

  contactForm!: FormGroup;
  submitted = false;

  constructor(private contactUsService: ContactUsService, public eventEmitterService: EventEmitterService, private notifyService: NotificationService, private recaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      vName: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(80)]),
      vEmailId: new FormControl("", [Validators.required, Validators.email, Validators.maxLength(70), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
      vPhoneNo: new FormControl("", [Validators.required, Validators.min(0)]),
      txMessage: new FormControl("", [Validators.required]),
    });
  }

  get has(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  SaveContactDetails() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.recaptchaV3Service.execute("ContactUs").subscribe((token: string) => {
      // console.debug(`Token [${token}] generated`);
      if (token) {
        this.contactUsService.ContactUs(this.contactForm.value).then((result: any) => {
          if (result.code == 200) {
            // this.eventEmitterService.modelMessage({ "msg":result.message, "status":0 });
            this.notifyService.showSuccess(result.message, "");
            this.contactForm.reset();
            this.submitted = false;
          } else {
            // this.eventEmitterService.modelMessage({ "msg":result.message, "status":1 });
            this.notifyService.showError(result.message, "");
          }
        });
      } else {
        this.notifyService.showError("We have received suspicious traffic from your side. Please try again later.", "");
      }
    });
  }
}

<main class="frontpage">
  <section class="banner w-100 linear-bg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="banner-block banner-bg parallax">
            <h1 class="banner-caption-new order-2 order-xl-1 change1" data-aos="fade-up">
              <div class="banner-caption-new-block">
                <span class="banner-caption-new-type-1">Accelerate Your</span>
              </div>
              <div class="banner-caption-new-block banner-caption-underline">
                <span class="banner-caption-new-type-3">Product Development</span>
              </div>
              <p class="banner-caption-new-type-5">
                <strong>Gain immediate access</strong><br>
                to the <strong class="darkbluetext">Available Bench</strong> &<br>
                <strong class="purpletext">Market-ready Technologies</strong> of the<br>
                <strong>world’s most innovative companies.</strong>
              </p>
            </h1>
            <div class="banner-block-img plx-height order-1 order-xl-2" data-aos="fade-up">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="banner-blockchain w-100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="banner-bottom">
            <div class="banner-bottom-info-block">
              <h2 class="banner-bottom-caption" data-aos="fade-up">
                <div class="banner-bottom-caption-type">Rapidise.io is the world’s only AI & Blockchain-enabled TTaaS
                  Platform</div>
              </h2>
              <p class="banner-bottom-caption-text" data-aos="fade-up">
                "Which accelerates your product engineering by providing you
                global access to companies with qualified talent to lease, &
                market-ready technologies to sell."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="market w-100 front-section-padding-tb">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="market-top-block" data-aos="fade-up">
            <h2 class="market-top-title-block">
              <span class="market-top-title">
                Talent & Technology as a Service to Expedite Your
                <strong class="market-title-bold">Time-to-Market</strong>
              </span>
            </h2>
            <div class="market-btns">
              <button type="button" name="button" class="market-btn market-lease-btn" routerLink="/lease">
                Lease My Bench
              </button>
            </div>
          </div>
          <p class="market-top-title-text" data-aos="fade-up">
            Rapidise.io allows you to log in and:
          </p>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <div class="market-card">
            <h3 class="market-card-title">
              <span class="market-card-icon darklightbluebg"><img [src]="imagePath + 'lease-key-icon.svg'"
                  alt="lease-key-icon" /></span>
              <span class="market-card-title-text"><strong>Hire</strong> Qualified Talent from an ISO & CMMI+
                Audited Company</span>
            </h3>
            <p class="market-card-text">
              In the form of a single engineer, a dedicated team, or an entire
              offshore development center.
            </p>
            <span class="market-card-icon-lg"><img [src]="imagePath + 'lease.svg'" alt="lease" /></span>
          </div>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <div class="market-card">
            <h3 class="market-card-title">
              <span class="market-card-icon lightpurplebg"><img [src]="imagePath + 'purchase-icon.svg'"
                  alt="purchase-icon" /></span>
              <span class="market-card-title-text"><strong>Purchase</strong> Ready-made Technology Solutions</span>
            </h3>
            <p class="market-card-text">
              Such as a SOM or SoC board for immediate integration into your
              engineering project.
            </p>
            <span class="market-card-icon-lg"><img [src]="imagePath + 'purchase.svg'" alt="purchase" /></span>
          </div>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <div class="market-card">
            <h3 class="market-card-title">
              <span class="market-card-icon lightgreenbg"><img [src]="imagePath + 'license-icon.svg'"
                  alt="license" /></span>
              <span class="market-card-title-text"><strong>License</strong> Fully Developed IP’s</span>
            </h3>
            <p class="market-card-text">
              Such as AR/VR SDK’s, Technical Schematics, and SaaS frameworks for
              instant inclusion into your product development.
            </p>
            <span class="market-card-icon-lg"><img [src]="imagePath + 'license.svg'" alt="license" /></span>
          </div>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <div class="market-card">
            <h3 class="market-card-title">
              <span class="market-card-icon lightbluebg"><img [src]="imagePath + 'set-up-icon.svg'"
                  alt="set-up-icon" /></span>
              <span class="market-card-title-text"><strong>Set Up</strong> an Onsite or Remote Ecosystem
                Service</span>
            </h3>
            <p class="market-card-text">
              Such as an IoT R&D Lab, PCB Assembly, or Product Manufacturing as
              the next stepping stone towards your commercialization.
            </p>
            <span class="market-card-icon-lg"><img [src]="imagePath + 'setup.svg'" alt="setup" /></span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="trusted w-100 front-section-padding-tb" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="trusted-top-title"><span>Trusted</span> by</h2>
          <owl-carousel-o [options]="customOptions" class="trusted-clients">
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-1.png'" alt="logo-1" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-2.png'" alt="logo-2" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-3.png'" alt="logo-3" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-4.png'" alt="logo-4" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-5.png'" alt="logo-5" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <section class="stands w-100 front-section-padding-tb">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-md-4" data-aos="fade-up">
          <h2 class="stands-top-title">
            <span>Rapidise.io</span> Stands Alone in the Market
          </h2>
          <p class="stands-title-text">
            Strengthened Data Security, Reduced Liability Concerns, & Expedited
            Time-to-market:
          </p>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightgreenbg greentext">1</span>
            <p class="stands-card-text">
              All Talent is hired directly from an ISO & CMMI+ audited company
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon darklightbluebg darkbluetext">2</span>
            <p class="stands-card-text">
              All Technology Solutions listed in the Technology Marketplace are fully-matured & market-ready
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightpurplebg purpletext">3</span>
            <p class="stands-card-text">
              AI-enabled Engine to execute exact-matching of a Client’s Talent & Technology requirements
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightbluebg lightbluetext">4</span>
            <p class="stands-card-text">
              All listed Companies & Talent are verified & non-fungible via Blockchain
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightpurplebg purpletext">5</span>
            <p class="stands-card-text">
              All Contracts are encrypted, self-executing, & non-fungible via Blockchain
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightgreenbg greentext">6</span>
            <p class="stands-card-text">
              All Payments are 100% guaranteed via Blockchain-enabled Smart Contract
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightbluebg lightbluetext">7</span>
            <p class="stands-card-text">
              2 Week Free Trial- 100% Talent Placement Guarantee within 2 weeks of Project Kickoff
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon darklightbluebg darkbluetext">8</span>
            <p class="stands-card-text">
              100% refund no questions asked within 2 Weeks of Free Trial
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightpurplebg purpletext">9</span>
            <p class="stands-card-text">
              Allows any company to lease available Talent, & sell market-ready Technologies
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 stands-box" data-aos="fade-up">
          <div class="stands-card">
            <span class="stands-card-icon lightgreenbg greentext">10</span>
            <p class="stands-card-text">
              24/7 online support provided to all Clients & Partners
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="blockchain w-100 front-section-padding-tb pb-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12 blockchain-box" data-aos="fade-up">
          <div class="blockchain-left-block">
            <h2 class="blockchain-title">
              Powered by <span>AI</span>, Secured by the <span>Blockchain</span>
            </h2>
            <p class="blockchain-text">
              Rapidise.io leverages AI to match you with perfect-fit talent, and
              the blockchain to fully secure all transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12 blockchain-box" data-aos="fade-up">
          <div class="blockchain-right-block">
            <perfect-scrollbar class="box-image">
              <img [src]="imagePath + 'rapidise-overview.png'" alt="rapidise-overview" />
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-front-footer></app-front-footer>
</main>
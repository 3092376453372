<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
  (click)="onItemSelected(item,item.disabledPublishDefaultProfile,item.completePublishProfile)"
  [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
  class="menu-list-item {{item.className}}">
  <span *ngIf="item.route ? router.isActive(item.route, true) : false; else elseBlock">
    <mat-icon class="routeIcon"><img [src]="imagePath + item.activeIconName" alt="{{item.displayName}}"
        width="24px" height="24px"></mat-icon>
  </span>
  <ng-template #elseBlock>
    <mat-icon class="routeIcon"><img [src]="imagePath + item.iconName" alt="{{item.displayName}}" width="24px"
        height="24px"></mat-icon>
  </ng-template>
  {{item.displayName}}
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
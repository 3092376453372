<perfect-scrollbar class="app-perfect-scrollbar">
    <div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
        <div class="row w-100 h-100 m-auto">
            <div name="left-side" id="left-side" class="col-12 col-lg-5 d-none d-lg-block p-0">
                <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-solid-box">
                    <p name="text" id="text" class="text blue-color">Share your Requirement</p>
                    <p name="sub-text" id="sub-text" class="sub-text">We have Expertise Talents</p>
                    <img id="section-side-image" class="section-side-image"
                        [src]="imagePath + 'requirement-details.png'" alt="section-image">
                </div>
            </div>
            <div name="right-side" id="right-side" class="col-12 col-lg-7 p-0 requirement-details">
                <div name="section-box" id="section-box" class="section-box">
                    <header name="section-header" id="section-header" class="section-header">
                        <img id="rapidise-logo" class="logo-cursor" [src]="imagePath + 'rapidise-logo.svg'"
                            alt="Rapidise" routerLink="/" />
                    </header>
                    <section class="section-content">
                        <div *ngIf="question == 1">
                            <div class="form-header">
                                <h4 class="form-title">What talent you are looking for?</h4>
                                <h6 class="requirement-label">Select the relevant job post for your project</h6>
                            </div>
                            <form [formGroup]="firstFormGroup" class="form-width">
                                <div name="form-content" id="form-content" class="form-content field-box">
                                    <div class="requirement-section">
                                        <mat-form-field class="d-block requirement-input-with-chip custom-input custom-search-input"
                                            floatLabel='never'>
                                            <mat-chip-list #chipList1 aria-label="Talent selection"
                                            class="chip-selected">
                                            <mat-chip *ngFor="let talent of talents">
                                                {{talent.name}}
                                            </mat-chip>
                                                <input matInput formControlName="talent" #talentInput
                                                    placeholder="Search Talent"
                                                    (keyup)="_filterTalent(firstFormGroup.controls['talent'].value)"
                                                    [matAutocomplete]="auto1" [matChipInputFor]="chipList1"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="talentInput.value = ''">
                                                <button matPrefix>
                                                    <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="20" viewBox="0 0 20 20">
                                                    <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z"
                                                    fill="none" />
                                                    <path id="Path_690" data-name="Path 690"
                                                    d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                                                    transform="translate(0)" fill="#464646" />
                                                    </svg>
                                                </button>
                                            </mat-chip-list>
                                            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="DisplayFnTalent"
                                            (optionSelected)="AddTalent($event.option.value);talentInput.value = ''">
                                                <mat-option *ngFor="let option of filteredTalents" [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                        <h6 class="requirement-label mb-0">Find our expert Talents</h6>
                                        <input type="text" hidden="true" matInput formControlName="talentcount"
                                            [(ngModel)]="talents.length">
                                        <div class="mt-2 mb-5">
                                            <mat-chip-list #chipListTalent aria-label="Talent selection"
                                                class="chip-not-selected">
                                                <mat-chip *ngFor="let obj of allTalents" [selectable]="selectable"
                                                    (click)="AddTalent(obj)">
                                                    {{obj.name}}
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                    </div>
                                </div>
                                <div name="form-footer" id="form-footer" class="form-footer d-flex justify-content-end">
                                    <button type="button" name="form-button" id="form-button" class="primary-btn"
                                        [disabled]="talents.length>0 ? false:true" (click)="viewQuestion2()">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="question == 2">
                            <div class="form-header">
                                <h4 class="form-title">What skills does your work require?</h4>
                                <h6 class="requirement-label">Search skills or Add your own for</h6>
                            </div>
                            <form [formGroup]="secondFormGroup" class="form-width">
                                <div name="form-content" id="form-content" class="form-content field-box">
                                    <mat-form-field floatLabel='never' hideRequiredMarker
                                    class="custom-padding custom-input custom-search-input" floatLabel="never"
                                    appearance="fill">
                                        <input matInput formControlName="skill" placeholder="Search Skill" #skillInput
                                            (keyup)="_filterSkill(secondFormGroup.controls['skillCount'].value)" [matAutocomplete]="auto2">
                                        <button matPrefix>
                                            <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20"
                                                height="20" viewBox="0 0 20 20">
                                                <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z"
                                                    fill="none" />
                                                <path id="Path_690" data-name="Path 690"
                                                    d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
                                                    transform="translate(0)" fill="#464646" />
                                            </svg>
                                        </button>
                                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="DisplayFnSkill"
                                            (optionSelected)="AddSkill($event)">
                                            <mat-option *ngFor="let option of filteredSkills" [value]="option">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <input type="text" hidden="true" matInput formControlName="skillCount">
                                    <div class="requirement-section">
                                        <div class="d-flex flex-wrap justify-content-even">
                                            <mat-checkbox class="custom-checkbox" *ngFor="let skills of allSkills;let i=index;" [(ngModel)]="skills.checked" [ngModelOptions]="{standalone: true}" (change)="temp()">{{skills.name}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div name="form-footer" id="form-footer"
                                    class="form-footer d-flex justify-content-between">
                                    <button type="button" name="form-button" id="form-button" class="back-btn"
                                        (click)="viewQuestion1()">
                                        Back
                                    </button>
                                    <button type="button" name="form-button" id="form-button" class="primary-btn"
                                        (click)="viewQuestion3()" [disabled]="selectedSkill.length>0 ? false:true">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="question == 3">
                            <div class="form-header">
                                <h4 class="form-title">What level of experience will it need?</h4>
                            </div>
                            <form [formGroup]="thirdFormGroup" class="form-width">
                                <div name="form-content" id="form-content" class="form-content field-box">
                                    <div class="requirement-section">
                                        <mat-radio-group aria-labelledby="years-of-experience"
                                            class="experience-radio-group d-flex flex-column"
                                            formControlName="yearsOfExperience">
                                            <mat-radio-button class="radio-button mb-2"
                                                *ngFor="let experience of experienceArray" [value]="experience.id">
                                                {{ experience.name }}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div name="form-footer" id="form-footer"
                                    class="form-footer d-flex justify-content-between">
                                    <button type="button" name="form-button" id="form-button" class="back-btn"
                                        (click)="viewQuestion2()">
                                        Back
                                    </button>
                                    <button type="button" name="form-button" id="form-button" class="primary-btn"
                                        [disabled]="thirdFormGroup.controls['yearsOfExperience'].value || thirdFormGroup.controls['yearsOfExperience'].value===0 ? false:true"
                                        (click)="viewQuestion4()">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="question == 4">
                            <div class="form-header">
                                <h4 class="form-title">What number of talents you will need?</h4>
                            </div>
                            <form [formGroup]="fourthFormGroup" class="form-width">
                                <div name="form-content" id="form-content" class="form-content field-box">
                                    <div class="requirement-section d-flex flex-column align-items-baseline">
                                        <div class=" d-flex align-items-baseline">
                                            <h6 class="number-label">Enter Numbers</h6>
                                        <mat-form-field hideRequiredMarker floatLabel='never'
                                            class="requirement-input num-input custom-dropdown form">
                                            <input type="number" matInput formControlName="talentNumberRequired"
                                                autocomplete="off" min="1" max="200" [(ngModel)]="defaultNumber" pattern="^[1-9]+$" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required>
                                        </mat-form-field>
                                        </div>
                                        <div class="h-error">
                                            <mat-error *ngIf="defaultNumber>200">Please enter value upto 200</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div name="form-footer" id="form-footer"
                                    class="form-footer d-flex justify-content-between">
                                    <button type="button" name="form-button" id="form-button" class="back-btn"
                                        (click)="viewQuestion3()">
                                        Back
                                    </button>
                                    <button type="button" name="form-button" id="form-button" class="primary-btn"
                                        (click)="GetStarted()"
                                        [disabled]="fourthFormGroup.controls['talentNumberRequired'].value <=200 && fourthFormGroup.controls['talentNumberRequired'].value > 0 ? false:true">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div *ngIf="question == 5">
                            <div class="form-header" *ngIf="isRequirementFullfilled">
                                <h4 class="form-title text-center">We found the best profiles for you..</h4>
                            </div>
                            <div class="form-header no-requirement" *ngIf="!isRequirementFullfilled">
                                <h4 class="form-title">No matching talent. Suggestions for similar skills with different experience available.</h4>
                            </div>
                            <div name="form-content" id="form-content" class="form-content requirement-card-width">
                                <div class="my-3">
                                    <div class="row g-3">
                                        <div class="col-xxl-4 col-sm-6 col-12" *ngFor="let emp of talentsList">
                                            <div class="profiles-card mb-2">
                                                <img class="profile-image" src="{{emp.vProfilePhoto}}" alt="Profile">
                                                <p class="talent-name">{{emp.vFirstName}} {{emp.vLastName}}</p>
                                                <p class="talent-skill blue-color">{{emp.primarySkillName}}</p>
                                                 <p class="talent-skill" *ngIf="emp.fEmpExperience >= 1">{{ (emp.fEmpExperience).toString().split(".")[0] }}+ Years Exp</p>
                                                <p class="talent-skill" *ngIf="emp.fEmpExperience < 1">{{emp.fEmpExperience | number | slice: 2}}+ Months Exp</p>
                                                <p class="talent-bio">{{emp.txBio}}<span *ngIf="emp?.txBio.length==175">...<span class="blue-color" (click)="More()">More</span></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column align-items-center mt-4">
                                        <p name="text" id="text" class="text blue-color mb-0 fw-bold f-text">We have more 100+ Talent
                                            for you..</p>
                                        <p name="sub-text" id="sub-text" class="sub-text f-text">Activate your account Now.</p>
                                    </div>
                                </div>
                            </div>
                            <div name="form-footer" id="form-footer"
                                class="form-footer d-flex flex-column align-items-center">
                                <button type="button" name="form-button" id="form-button" class="primary-btn" (click)="ActivateNow()">
                                    Activate Now
                                </button>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    </div>
</perfect-scrollbar>
import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { NavItem } from "../nav-item";
import { Router } from "@angular/router";
import { NavService } from "../nav.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { NotificationService } from "../../config/notification.service";
import { environment } from "./../../../environments/environment";

@Component({
  selector: "app-menu-list-item",
  templateUrl: "./menu-list-item.component.html",
  styles: [],
  animations: [trigger("indicatorRotate", [state("collapsed", style({ transform: "rotate(0deg)" })), state("expanded", style({ transform: "rotate(180deg)" })), transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4,0.0,0.2,1)"))])],
})
export class MenuListItemComponent implements OnInit {
  imagePath = environment.image_path;

  expanded!: boolean;
  @HostBinding("attr.aria-expanded") ariaExpanded = this.expanded;
  @Input() item!: NavItem;
  @Input() depth!: number;

  constructor(public navService: NavService, public router: Router, private notifyService: NotificationService) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item!.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem, disabledPublishDefaultProfile: any, completePublishProfile: any) {
    if (!item.children || !item.children.length) {
      if (disabledPublishDefaultProfile == true && item.route == "/partner/view-profile") {
        this.router.navigate([item.route]);
      } else if ((completePublishProfile == true && item.route == "/partner/view-profile") || (completePublishProfile == true && item.route == "/partner") || (completePublishProfile == true && item.route == "/partner/employees")) {
        this.router.navigate([item.route]);
      } else if (completePublishProfile == false || disabledPublishDefaultProfile == false) {
        this.notifyService.showWarning("Unauthorized Access!", "");
      } else if (completePublishProfile == true && disabledPublishDefaultProfile == true) {
        this.router.navigate([item.route]);
      }
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { environment } from "./../../environments/environment";

@Component({
  selector: "app-journey",
  templateUrl: "./journey.component.html",
})
export class JourneyComponent implements OnInit {
  imagePath = environment.image_path;

  isLoad: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      this.isLoad = true;
    }, 50);
  }

  customOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 30,
    navText: ['<img src="' + this.imagePath + 'left-arrow-black.svg" alt="left">', '<img src="' + this.imagePath + 'right-arrow-black.svg" alt="right">'],
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      992: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  };
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "./../../../environments/environment";

@Component({
  selector: "app-front-footer",
  templateUrl: "./front-footer.component.html",
})
export class FrontFooterComponent implements OnInit {
  imagePath = environment.image_path;

  public CurrentYear = new Date().getFullYear();

  constructor(public router: Router) {}

  ngOnInit(): void {}
}

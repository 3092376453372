import { Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavService {
  public homeAppDrawer: any;
  public appDrawer: any;
  public sideNavEnd: any;

  public currentUrl = new BehaviorSubject<string>(undefined!);

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeHomeNav() {
    this.homeAppDrawer.close();
  }

  public openHomeNav() {
    this.homeAppDrawer.open();
  }

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

  public closeEndNav() {
    this.sideNavEnd.close();
  }

  public openEndNav() {
    this.sideNavEnd.open();
  }
}

<main class="frontpage about-us">
  <section class="banner w-100 linear-bg">
    <div class="container-fluid content w-100">
      <div class="row">
        <div class="col-12">
          <div class="banner-card d-flex justify-content-center align-items-center">
            <h2 class="title">About Rapidise.io</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Rapidise.io is the world's first only -->
  <section class="rapidise-world">
    <div class="container">
      <div class="row">

        <div class="col-lg-7">
          <div class="banner-content">
            <h2 class="banner-content-title"><span class="grn-text">Rapidise.io</span> is the world's first & only</h2>
            <p>AI & Blockchain-enabled TTaaS platform which accelerates product engineering by providing users global
              access to companies with qualified talent to lease, & market-ready technologies to sell.
            </p>
          </div>
        </div>
        <div class="col-lg-5 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
          <figure class="d-flex justify-content-center">
            <img [src]="imagePath + 'cybersecurity.png'" alt="cybersecurity" class="img-fluid">
          </figure>
        </div>

      </div>
    </div>
  </section>

  <!-- Rapidise.io Promise -->
  <section class="rapidise-promise">
    <div class="container">
      <div class="row align-items-center">
        <h2>Rapidise.io <strong>Promise</strong> : Expediting Your <strong> Time-to-Market</strong></h2>
      </div>
    </div>
  </section>

  <!-- Core Guiding Engineering Principle -->
  <section class="core-guiding">
    <div class="container">
      <h2 class="core-guiding-title">Core Guiding <span class="grn-text">Engineering Principle</span></h2>
      <div class="sub-title">Rapidise.io TTaaS platform provides Immediate Access to the Available Talent & Market-ready
        Technologies of the World’s Most Innovative Companies.</div>
      <h2>Core <span class="grn-text">Values</span></h2>
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-1.svg'" alt="icon-1" class="img-fluid"> </div>
            <h3>Delivering World-Class Talent & Technology</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-2.svg'" alt="icon-2" class="img-fluid"> </div>
            <h3>Crafting a Perfect User Experience</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-3.svg'" alt="icon-3" class="img-fluid"> </div>
            <h3>Facilitating Win-Win Relationships</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-6.svg'" alt="icon-6" class="img-fluid"> </div>
            <h3>Leveraging Technology For the Global Good</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-5.svg'" alt="icon-5" class="img-fluid"> </div>
            <h3>Maintaining the Highest Moral & Ethical Standards</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="top-icon-box">
            <div class="icon-box"><img [src]="imagePath + 'icon-4.svg'" alt="icon-4" class="img-fluid"> </div>
            <h3>Fostering Innovation in Every Opportunity</h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- The Rapidise Story -->
  <section class="rapidise-story">
    <div class="container">
      <div class="row">

        <div class="col-lg-7">
          <h2 class="rapidise-story-title">The Rapidise <span class="grn-text">Story</span></h2>
          <p class="rapidise-story-text">Deciding to scratch our own itch by building a platform to accelerate product
            engineering, Rapidise was founded by a handful of technology professionals who work daily in the realm of
            IoT and AI/ML product design, development, systems
            integration and manufacturing. Given our unique perspective within the global technology marketplace, we
            have built this platform to solve the challenges we face first-hand when the time comes to scale up a
            qualified development
            team, implement matured IP’s & technology solutions, and get to market FAST.</p>
          <p><strong>Read the Founder & President’s entire journey which led to Rapidise <a href="javascript:void(0)"
                routerLink="/journey">HERE...</a></strong></p>
        </div>
        <div class="col-lg-5">
          <figure>
            <img [src]="imagePath + 'rapidise-story.png'" alt="rapidise story" class="img-fluid">
          </figure>
        </div>
      </div>
    </div>
  </section>

  <!-- Core Executive Team -->
  <section class="core-team">
    <div class="container">
      <h2 class="core-team-title"><span class="grn-text">Core Executive</span> Team</h2>
      <div class="row g-4 align-items-center">
        <div class="col-lg-6">
          <div class="core-team-box">
            <div class="img-box">
              <img [src]="imagePath + 'brijesh-kamani.png'" alt="Brijesh Kamani" class="img-fluid">
            </div>
            <div class="text-box">
              <h3>Brijesh Kamani</h3>
              <p><strong>Founder & President</strong> San Fransisco, CA</p>
              <a href="https://www.linkedin.com/in/brijesh-kamani" target="_blank">
                <img class="social-icon" [src]="imagePath + 'linkedin.png'" alt="social icon">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="core-team-box">
            <div class="img-box">
              <img [src]="imagePath + 'tyler.png'" alt="Tyler" class="img-fluid">
            </div>
            <div class="text-box">
              <h3>Tyler Marshall</h3>
              <p> <strong>Co-Founder & Chief Product Officer</strong> Denver, CO</p>
              <a href="https://www.linkedin.com/in/tylermarshall38" target="_blank">
                <img class="social-icon" [src]="imagePath + 'linkedin.png'" alt="social icon">
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <!--Your Pain-points -->
  <section class="pain-points">
    <div class="container">
      <h2 class="pain-points-title">Your Pain-points, <span class="grn-text">Solved by Rapidise.io</span></h2>
      <div class="row">
        <div class="col-lg-6">
          <h3>Problem</h3>
          <div class="problem-point first">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union"
                  class="img-fluid"></span>Inherent liability in blind-hiring of unqualified & unreliable freelance
              developers</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union"
                  class="img-fluid"></span>Inherent liability in blind-hiring of unqualified & unreliable freelance
              developers</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union" class="img-fluid"></span>Unable
              to access and quickly integrate market-ready technologies and IP’s into my product development</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union"
                  class="img-fluid"></span>Excessive time spent by my HR in Talent Screening, JD Mapping, and Aligning
              the Right Profiles</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union" class="img-fluid"></span>Lack of
              accuracy, mutual transparency, speed of execution, and security in project contracts</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union" class="img-fluid"></span>Late,
              untimely, and/or non-submitted project contract payments</p>
          </div>
          <div class="problem-point">
            <p><span class="icon-box"> <img [src]="imagePath + 'union.svg'" alt="union" class="img-fluid"></span>Lack of
              continued support, once I hire remote talent and/or purchase market-ready technologies</p>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h3 class="green-text">Solved by <span class="grn-text">Rapidise.io</span></h3>
          </div>
          <div class="check-list-space">
            <ul class="check-list first">
              <li> All Talent is hired directly from an ISO & CMMI+ audited company</li>
              <li>All listed Companies & Talent are verified & non-fungible via Blockchain
              </li>
              <li>2 Week Free Trial- 100% Talent Placement Guarantee within 2 weeks of
                Project Kickoff</li>
              <li>100% refund no questions asked within 2 Weeks of Free Trial</li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li>All Technology Solutions listed in the Technology Marketplace are
                fully-matured & market-ready</li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li>AI-enabled Engine to execute exact-matching of a Client’s Talent &
                Technology requirements</li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li>All Contracts are encrypted, self-executing, & non-fungible via Blockchain
              </li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li> All Payments are 100% guaranteed via Blockchain-enabled Smart Contract
              </li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li>24/7 online support provided to all Clients & Partners</li>
            </ul>
          </div>
          <div class="check-list-space">
            <ul class="check-list">
              <li> Allows any company to monetize existing assets by leasing available talent
                bench & selling market-ready technologies</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Trusted by-->
  <section class="trusted w-100 front-section-padding-tb pb-0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="trusted-top-title"><span>Trusted</span> by</h2>
          <owl-carousel-o [options]="customOptions" class="trusted-clients">
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-1.png'" alt="logo-1" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-2.png'" alt="logo-2" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-3.png'" alt="logo-3" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-4.png'" alt="logo-4" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-5.png'" alt="logo-5" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>

  <app-front-footer></app-front-footer>
</main>
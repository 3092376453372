<main class="frontpage tmarket faq">
  <section class="banner w-100 linear-bg">
    <div class="container-fluid content w-100">
      <div class="row">
        <div class="col-12">
          <div class="banner-card d-flex justify-content-center align-items-center">
            <h2 class="title">FAQs</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="time-to-market w-100 front-section-padding-tb pb-0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="mycard">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="p-5 faq-content">
                <span class="d-flex">
                  <h2 class="card-title"><span>Premium</span> Support</h2>
                </span>
                <p class="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In faucibus turpis tortor, sit amet tincidunt
                  nisl molestie efficitur. Sed sit amet blandit neque. Vivamus accumsan nisl at tempor ullamcorper.
                  Fusce vehicula diam id eros
                  venenatis, in porttitor massa suscipit. Proin eu mollis magna. Maecenas iaculis gravida metus, id
                  maximus nulla fringilla vel. In porta est at ligula sagittis, quis scelerisque sapien facilisis.
                </p>
              </div>
              <div class="faq-img">
                <img [src]="imagePath + 'male-female-hands-holding-isolated-blue.png'" class="img-fluid"
                  alt="male-female-hands-holding-isolated-blue">
              </div>
            </div>
          </div>

          <div class="mid-section front-section-padding-tb">
            <div class="row g-4 flex-wrap">
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <span class="d-flex faq-pd-2">
                  <img [src]="imagePath + 'rocket-launch.svg'" alt="rocket-launch" class="img">
                  <h5 class="font-weight-bold">Fast Responding</h5>
                </span>
                <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In faucibus turpis tortor, sit
                  amet tincidunt nisl molestie efficitur. Sed sit amet blandit neque. Vivamus accumsan nisl at tempor
                  ullamcorper.</p>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <span class="d-flex faq-pd-2">
                  <img [src]="imagePath + 'headset-mic.svg'" alt="headset-mic" class="img">
                  <h5 class="font-weight-bold">Experienced Employees</h5>
                </span>
                <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In faucibus turpis tortor, sit
                  amet tincidunt nisl molestie efficitur. Sed sit amet blandit neque. Vivamus accumsan nisl at tempor
                  ullamcorper.</p>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <span class="d-flex faq-pd-2">
                  <img [src]="imagePath + 'speed.svg'" alt="speed" class="img">
                  <h5 class="font-weight-bold">High Work Speed</h5>
                </span>
                <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In faucibus turpis tortor, sit
                  amet tincidunt nisl molestie efficitur. Sed sit amet blandit neque. Vivamus accumsan nisl at tempor
                  ullamcorper.</p>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="d-flex flex-column align-items-start w-100">
              <div class="nav flex-row nav-pills w-100 m-0" id="v-pills-tab" role="tablist"
                aria-orientation="horizontal">
                <a class="nav-link active" id="client-tab" data-bs-toggle="pill" href="#client" role="tab"
                  aria-controls="client" aria-selected="false">Client</a>
                <a class="nav-link" id="contractor-tab" data-bs-toggle="pill" href="#contractor" role="tab"
                  aria-controls="contractor" aria-selected="true">Contractor</a>
                <a class="nav-link" id="employee-tab" data-bs-toggle="pill" href="#employee" role="tab"
                  aria-controls="employee" aria-selected="false">Employee</a>
              </div>
              <h2 class="faq-title front-section-padding-tb-1 w-100 d-flex justify-content-center">Frequently Asked
                Questions</h2>
              <div class="tab-content flex-grow-1 w-70" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="client" role="tabpanel"
                  aria-labelledby="partner-dictory-tab">
                  <mat-accordion>
                    <mat-expansion-panel class="faq-pad-1" (opened)="panelOpenState1 = true "
                      (closed)="panelOpenState1 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between active w-100"
                          [style.color]=" panelOpenState1 ? '#5680E9' : 'black' ">
                          01. What does having Managed your services provider?
                          <mat-icon *ngIf="!panelOpenState1">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState1">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState2 = true"
                      (closed)="panelOpenState2 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState2 ? '#5680E9' : 'black' ">
                          02. What you about say your Business planning?
                          <mat-icon *ngIf="!panelOpenState2">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState2">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState3 = true"
                      (closed)="panelOpenState3 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState3 ? '#5680E9' : 'black' ">
                          03. You have a unique way of the working in IT?
                          <mat-icon *ngIf="!panelOpenState3">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState3">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState4 = true"
                      (closed)="panelOpenState4 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState4 ? '#5680E9' : 'black' ">
                          04. What types of systems do you support?
                          <mat-icon *ngIf="!panelOpenState4">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState4">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState5 = true"
                      (closed)="panelOpenState5 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState5 ? '#5680E9' : 'black' ">
                          05. Can you provide of all IT Management services?
                          <mat-icon *ngIf="!panelOpenState5">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState5">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>


                <div class="tab-pane fade " id="contractor" role="tabpanel" aria-labelledby="partner-dictory-tab">
                  <mat-accordion>
                    <mat-expansion-panel class="faq-pad-1" (opened)="panelOpenState1 = true"
                      (closed)="panelOpenState1 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between active w-100"
                          [style.color]=" panelOpenState1 ? '#5680E9' : 'black' ">
                          01. What does having Managed your services provider?
                          <mat-icon *ngIf="!panelOpenState1">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState1">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState2 = true"
                      (closed)="panelOpenState2 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState2 ? '#5680E9' : 'black' ">
                          02. What you about say your Business planning?
                          <mat-icon *ngIf="!panelOpenState2">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState2">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState3 = true"
                      (closed)="panelOpenState3 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState3 ? '#5680E9' : 'black' ">
                          03. You have a unique way of the working in IT?
                          <mat-icon *ngIf="!panelOpenState3">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState3">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div class="tab-pane fade " id="employee" role="tabpanel" aria-labelledby="partner-dictory-tab">
                  <mat-accordion>
                    <mat-expansion-panel class="faq-pad-1" (opened)="panelOpenState1 = true"
                      (closed)="panelOpenState1 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between active w-100"
                          [style.color]=" panelOpenState1 ? '#5680E9' : 'black' ">
                          01. What does having Managed your services provider?
                          <mat-icon *ngIf="!panelOpenState1">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState1">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState2 = true"
                      (closed)="panelOpenState2 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState2 ? '#5680E9' : 'black' ">
                          02. What you about say your Business planning?
                          <mat-icon *ngIf="!panelOpenState2">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState2">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState3 = true"
                      (closed)="panelOpenState3 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState3 ? '#5680E9' : 'black' ">
                          03. You have a unique way of the working in IT?
                          <mat-icon *ngIf="!panelOpenState3">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState3">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                    <mat-expansion-panel hideToggle class="faq-pad-1" (opened)="panelOpenState4 = true"
                      (closed)="panelOpenState4 = false" hideToggle="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="faq-active justify-content-between w-100"
                          [style.color]=" panelOpenState4 ? '#5680E9' : 'black' ">
                          04. What types of systems do you support?
                          <mat-icon *ngIf="!panelOpenState4">add</mat-icon>
                          <mat-icon *ngIf="panelOpenState4">remove</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p class="faq-text">Solution Analysts welcomes you to try our services. We invest the same time
                        and energy in Pilot Projects as real projects. We have always succeeded in continuing the
                        relationship after demonstration of ability in a pilot
                        project different from prototypes.</p>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-front-footer></app-front-footer>
</main>
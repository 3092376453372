export const environment = {
  dis_alert_timeout: 3000,
  production: true,
  home_page: "https://teksun.rapidise.io",
  baseUrl: "https://back.rapidise.io/api",
  partner_login_page: "https://partner.teksun.rapidise.io/login",
  client_login_page: "https://client.teksun.rapidise.io/login",
  emp_login_page: "https://employee.teksun.rapidise.io/login",
  image_path: "https://objectapi.teksun.com/rapidise-assets/images/",
  recaptcha: {
    api_key: "6LcGCSYiAAAAACJS-QZpKGsm7LFCNtpXDGKnDWYg",
  },
  cookiebot: {
    api_key: "3cce4ea0-d685-4d6d-aeae-a715203cbc56",
  },
  zendesk: {
    api_key: "5a7fb19b-194f-4995-a641-9355a7aaa63b",
  },
  amplitude: {
    api_key: "3f97f7ff652d394c022168d87899e05e",
  },
  sentry: {
    dsn: "https://4c954a387240417c8b1bb97eedda92ee@o1394344.ingest.sentry.io/6750481",
  },
  google_tag: {
    gtag_id: "G-1D32EK8WCE",
  },
  crypto_js: {
    key: "Rnm8iLsDRw45kopsKN",
  },
  aws: {
    key_id: "8001ac90-dd02-44fd-99fe-00ae40b78b2e",
  },
  aws_keys: {
    set_zero: [
      {
        ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
        SECRETACCESSKEY: "A4FFCVq-sf/pODrFH5VTdA8Kd+9QLq3t3AsXYOKo",
      },
      {
        ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
        SECRETACCESSKEY: "K3JVBFa+cs/UpsFsfE3sDc5DcS9QDqse4SsGsLWC",
      },
      {
        ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
        SECRETACCESSKEY: "J4DFYsv-sp/A8dEfKb23Fa4Sd3-acWWx35sNSdaTe",
      },
      {
        ACCESSKEYID: "ASIA3G38XBB7WRF3M4SN",
        SECRETACCESSKEY: "N4dfgHe-dj/rfxSxaS3SdCfLla8EFqwd6f7KsyTR",
      },
      {
        ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
        SECRETACCESSKEY: "L2lgaJs+me/RsdFtHLXsaEd8l52SqWsy4JvJsxWM",
      },
    ],
    set_one: [
      {
        ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
        SECRETACCESSKEY: "J2DHCEq+es/13jnDM3LF2R5H+fe8M4ak4EDCUmeY",
      },
      {
        ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
        SECRETACCESSKEY: "L4RFCSu-hl/OPF23LcDDb5fLa-34ff5gdECdSxqL",
      },
      {
        ACCESSKEYID: "ASIA3G38FBB3WRM3M4SN",
        SECRETACCESSKEY: "M1SDXDw+fu/IdZeSA4LM5LsL-sD4Lsfg2EsLKsgI",
      },
      {
        ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
        SECRETACCESSKEY: "I2DSL3q-dp/UOCLsE56Ls2WL-sa85Kemw36SlsfP",
      },
      {
        ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
        SECRETACCESSKEY: "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",
      },
    ],
    set_two: [
      {
        ACCESSKEYID: "ASIA3G38FBB3WRM3M4SN",
        SECRETACCESSKEY: "I5LsMw3+lw/LowcDL1nMdA8HtYkSDh+yLeRTjmYL",
      },
      {
        ACCESSKEYID: "AKIA4U47CDD8VBY6JAKL",
        SECRETACCESSKEY: "K2sCs2Q-sd/WqeRsx5wCaM8Kd5-JksJyusN+rWJd",
      },
      {
        ACCESSKEYID: "AKIA6H65CGD7JUY2YIVV",
        SECRETACCESSKEY: "A5DFL2d-pq/NOsxEMsLTelO6n+9QLpEk-Kl4nMjS",
      },
      {
        ACCESSKEYID: "ASIA2J76AFB8WKZ3FUCE",
        SECRETACCESSKEY: "H8dMd7w+en/eOIsCd1VTI+sIBFdhVq3t3AsdYsNM",
      },
      {
        ACCESSKEYID: "ASIA1D8GmN3EsHY2LIYB",
        SECRETACCESSKEY: "E6FDn2w-ws/ReDjtK3ABRs+4PU9BdgEklesXYlwT",
      },
    ],
  }
};

<section class="hire-developer w-100 front-section-padding-tb">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="hire-developer-card">
          <div class="text-section">
            <h2 class="hire-developer-title">
              Hire and Manage remote developers
            </h2>
            <p class="hire-developer-text">
              Tell us the skills you need and we'll find the best developer
              for you in days, not weeks.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer w-100 front-section-padding-tb">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img class="footer-logo" [src]="imagePath + 'rapidise-footer-logo.png'" alt="rapidise-logo" />
        <ul class="footer-nav-items">
          <li>
            <a href="javascript:void(0)" name="client-onboard-footer" id="client-onboard-footer"
              routerLink="/client-onboarding">Client Onboard</a>
          </li>
          <li>
            <a href="javascript:void(0)" name="lease-my-bench-footer" id="lease-my-bench-footer"
              routerLink="/lease">Lease My Bench</a>
          </li>
          <li>
            <a href="javascript:void(0)" name="about-footer" id="about-footer" routerLink="/about"
              [ngClass]="{'active-nav': router.isActive('/about', true)}">About</a>
          </li>
          <li>
            <a href="javascript:void(0)" name="contact-footer" id="contact-footer" routerLink="/contact"
              [ngClass]="{'active-nav': router.isActive('/contact', true)}">Contact</a>
          </li>
        </ul>
        <ul class="footer-social-items">
          <li>
            <a href="https://www.youtube.com/channel/UC9s8pHQOlJb5hVhUEz2BpRA" name="youtube-icon-footer"
              id="youtube-icon-footer" target="_blank"><img [src]="imagePath + 'youtube.svg'" alt="youtube" /></a>
          </li>
          <li>
            <a href="https://www.facebook.com/Rapidise.inc/" name="facebook-icon-footer" id="facebook-icon-footer"
              target="_blank"><img [src]="imagePath + 'facebook.svg'" alt="facebook" /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/rapidise.io/" name="instagram-icon-footer" id="instagram-icon-footer"
              target="_blank"><img [src]="imagePath + 'instagram.svg'" alt="instagram" /></a>
          </li>
          <li>
            <a href="https://twitter.com/rapidise_io" name="twitter-icon-footer" id="twitter-icon-footer"
              target="_blank"><img [src]="imagePath + 'twitter.svg'" alt="twitter" /></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/rapidise-inc/" name="linkedin-icon-footer"
              id="linkedin-icon-footer" target="_blank"><img [src]="imagePath + 'linkedin.svg'" alt="linkedin" /></a>
          </li>
          <li>
            <a href="https://g.page/r/CQ8AvdPFcfp3EBA" name="google-business-icon-footer"
              id="google-business-icon-footer" target="_blank"><img [src]="imagePath + 'google-my-business.svg'"
                alt="google-my-business" /></a>
          </li>
        </ul>
        <p class="footer-text footer-text-icon-block">
          <span class="footer-icon"><img [src]="imagePath + 'location.svg'" alt="location" /> 1607 South Main St,
            Milpitas, CA, USA 95035</span>
          <span class="footer-icon"><img [src]="imagePath + 'phone.svg'" alt="phone" />+1 (415) 851-7851</span>
        </p>
        <p class="footer-text">Rapidise.io &copy; {{CurrentYear}}</p>
        <p class="footer-disclaimer"><strong>Disclaimer:</strong> All the information contained, displayed, transmitted
          or carried on Rapidise.io website is solely protected by 
          <a routerLink="/privacy-policy" class="caption-link">Privacy Policy</a> 
          to provide general information on Company Code of Ethics and protected
          by Trademarks of Rapidise and other intellectual property laws. Your access to the website is subject to our
          <a routerLink="/terms-and-conditions" class="caption-link">Terms of Use.</a>
        </p>
      </div>
    </div>
  </div>
</footer>
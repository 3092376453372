<main class="frontpage journey">
  <!-- The Story -->
  <section class="story">
    <div class="container">
      <div class="story-box">
        <div class="img-box">
          <img [src]="imagePath + 'journy-banner.png'" alt="journy-banner" class="image">
          <p class="img-box-text"><span>The Story</span> (Founders Words)</p>
        </div>
        <div class="text-box">
          <p class="paraghraph">Pursued Post Graduation M.Tech Software Systems from BITS Pilani “Birla Institute of
            Technology and Science, Pilani” & B.Tech with Electronics & Communication always motivating to a journey
            toward hardware and software both which eventually lead me to establish Teksun Microsys Pvt Ltd in 2014 as
            an IoT & AI Technology consulting company with 300+ engineering manpower and 20+ countries serving clients.
          </p>
          <p class="paraghraph">Having experience of working with Hardware & Software together for Product Development,
            I have worked with Startup, SEM & Enterprise companies and found multiple opportunities for improvement
            product innovation by leveraging available resources (Talent & Technology) & partners. This really motivates
            to start some digital platform for partners to support each other and get monetization on passing lead /
            business / delivery, with this idea we started small software SaaS program called “Teksun Partner Platform”
            in 2015 but eventually it didn’t gets really traction due to idea was too early for market. I have travelled
            to more than 10+ countries and 356 Cities for Sales Campaigning & Relationship where I felt there is so much
            talent and technology available but there is no platform which can connect all dots to gather. Personally I
            believe not to reinvent the wheel and leverage existing technology solutions. So I was always looking for an
            opportunity to fill these gaps.</p>
          <p class="paraghraph">As a CEO for a product & service company I have seen CEO’s bandwidth go into many items
            where keeping eye on key focus activities of growth and revenue gets compromised due to looking around right
            talent for organization and right technology partner.</p>
          <p class="paraghraph">Having my love for Semiconductor and Electronics eventually led me to move to Silicon
            Valley in 2021, where coming to a new place & moving with family came to set up a new house and office where
            I was looking for labor / manpower to support. Eventually I found a couple of mobile apps by which I used to
            lease required labor and got the job done. This incident really triggered me to look back into building some
            innovative platform for solving product / engineering companies' problem of capturing Talent and Technology
            partners. Also COVID teaches everyone to keep minimum liabilities and get maximum outcome from it. Everyone
            adopted the culture of remote work. So I felt it's a good time to relook into what’s been vision earlier in
            2015.</p>
          <p class="paraghraph">I immediate started looking around a company which can offer immediate talent and
            technology partner ecosystem but only thing I found was companies offering freelancers, which really not
            serving need of product based company due to product confidentiality, NDA’s, Non Solicitations, trade
            secrets, labor compliances and other legal liabilities which is not possible in case of managing via
            freelancers. This is where the Rapidise idea came to my mind and started discussing with my existing
            clients, partners and advisors and we immediately started product MVP & market validation which gave a
            really good response and motivated us to get something started where we can contribute to 1M+ employment via
            platform and contribute to the global economy.</p>
          <p class="paraghraph">With more than 100+ engineering manpower & 150,000+ man hours of effort invested into
            inventing patent pending technology solution - Rapidise which enables Smart AI to identify client
            requirements and enable connecting with available talents & technology partners from global market with
            Smart Contracting over Blockchain technology to generate transparency between Client, Partner and Employee.
          </p>
          <p class="paraghraph"> We wish to be part of Global economic contribution and global employment of people with
            your support</p>
        </div>
        <div class="end-section d-flex justify-content-center">
          <img [src]="imagePath + 'page-end-logo.png'" alt="page-end-logo" class="image">
          <img [src]="imagePath + 'page-end-logo.png'" alt="page-end-logo" class="image space">
          <img [src]="imagePath + 'page-end-logo.png'" alt="page-end-logo" class="image">
        </div>
      </div>
    </div>
  </section>

  <!-- References & Resources -->
  <section class="references-and-resources">
    <div class="container-fluid">
      <h2 class="references-and-resources-title">References & Resources</h2>
      <div class="container-fluid references-and-resources-box">
        <div class="container">
          <owl-carousel-o [options]="customOptions" class="references-and-resources-carousal">
            <ng-template carouselSlide>
              <a class="img-box d-flex flex-column align-items-center"
                href="https://www.youtube.com/watch?v=FvMCXqMsFAI" target="_blank">
                <img [src]="imagePath + 'vision.png'" alt="vision" class="img">
                <p class="img-text">Vision</p>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a class="img-box d-flex flex-column align-items-center"
                href="https://www.youtube.com/watch?v=43wHERz8Kpk" target="_blank">
                <img [src]="imagePath + 'rapidise-name-story.png'" alt="vision" class="img">
                <p class="img-text">Rapidise Name Story</p>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a class="img-box d-flex flex-column align-items-center"
                href="https://www.youtube.com/watch?v=fvx3WtDk2e8" target="_blank">
                <img [src]="imagePath + 'rapidise-story-book.png'" alt="vision" class="img">
                <p class="img-text">Rapidise Story</p>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a class="img-box d-flex flex-column align-items-center"
                href="https://www.youtube.com/watch?v=C0BIjAZVNOk" target="_blank">
                <img [src]="imagePath + 'how-does-it-works.png'" alt="vision" class="img">
                <p class="img-text">How Does it Works</p>
              </a>
            </ng-template>
            <ng-template carouselSlide>
              <a class="img-box d-flex flex-column align-items-center" href="https://beta.rapidise.io/" target="_blank">
                <img [src]="imagePath + 'website.png'" alt="vision" class="img">
                <p class="img-text">Website</p>
              </a>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>

  <!-- Trusted by -->
  <section class="trusted w-100 front-section-padding-tb pb-0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="trusted-top-title"><span>Trusted</span> by</h2>
          <owl-carousel-o [options]="customOptions" class="trusted-clients">
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-1.png'" alt="logo-1" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-2.png'" alt="logo-2" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-3.png'" alt="logo-3" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-4.png'" alt="logo-4" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="trusted-clients-logo">
                <img [src]="imagePath + 'logo-5.png'" alt="logo-5" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>

  <app-front-footer></app-front-footer>
</main>
<mat-sidenav-container class="ins-sidenav partner front">

  <mat-toolbar class="front-header-toolbar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="front-header d-flex w-100">
            <div class="front-logo">
              <span class="logo-desktop"><img [src]="imagePath + 'rapidise-logo.svg'" alt="Rapidise"
                  routerLink="/" /></span>
              <span class="logo-mobile"><img [src]="imagePath + 'rapidise-logo-icon.svg'" alt="Rapidise"
                  routerLink="/" /></span>
            </div>
            <ul class="front-nav-items">
              <li>
                <a href="javascript:void(0)" name="client-onboard-button" id="client-onboard-button"
                  routerLink="/client-onboarding">Client Onboard</a>
              </li>
              <li>
                <a id="lease-my-bench-button" href="javascript:void(0)" routerLink="/lease">Lease My Bench</a>
              </li>
              <li>
                <a id="about-button" href="javascript:void(0)" routerLink="/about"
                  [ngClass]="{'active-nav': router.isActive('/about', true)}">About</a>
              </li>
              <li>
                <a id="contact-button" href="javascript:void(0)" routerLink="/contact"
                  [ngClass]="{'active-nav': router.isActive('/contact', true)}">Contact</a>
              </li>
            </ul>
            <ul class="front-login-items-block">
              <li *ngIf="!json_user_data.login_status">
                <a id="login-button" href="javascript:void(0)" class="front-login-btn" [matMenuTriggerFor]="loginMenu">
                  Login
                </a>
                <mat-menu #loginMenu="matMenu" class="header-matmenu header-matmenu-login header-matmenu_box">
                  <button name="partner-login-button" id="partner-login-button" mat-menu-item
                    (click)="login('partner')">
                    Login as Partner
                  </button>
                  <button name="client-login-button" id="client-login-button" mat-menu-item (click)="login('client')">
                    Login as Client
                  </button>
                  <button name="employee-login-button" id="employee-login-button" mat-menu-item
                    (click)="login('employee')">
                    Login as Employee
                  </button>
                </mat-menu>
              </li>
              <li *ngIf="json_user_data.login_status">
                <span *ngIf="!json_user_data.vProfilePhoto" class="header-profile-img" mat-button
                  [matMenuTriggerFor]="menu">{{json_user_data.dis_short_name}}</span>
                <img *ngIf="json_user_data.vProfilePhoto" [src]="json_user_data.vProfilePhoto"
                  class="header-profile-img" mat-button [matMenuTriggerFor]="menu" alt="menu" />
                <mat-menu #menu="matMenu" class="header-matmenu header-matmenu_box">
                  <button mat-menu-item name="dashboard-menu" id="dashboard-menu" routerLink="/partner">
                    Dashboard
                  </button>
                  <button mat-menu-item name="profile-settings" id="profile-settings"
                    routerLink="/partner/view-profile">
                    Profile Settings
                  </button>
                  <button mat-menu-item name="change-password" id="change-password"
                    routerLink="/partner/change-password">
                    Change Password
                  </button>
                  <button mat-menu-item name="sign-out-menu" id="sign-out-menu" (click)="logout()">
                    Sign Out
                  </button>
                </mat-menu>
              </li>
              <li>
                <a href="javascript:void(0)" name="menu" id="menu" class="front-menu" (click)="navService.openEndNav()">
                  Menu
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav #sideNavEnd mode="over" opened="false" position="end" class="side-nav-end">
    <div class="d-flex justify-content-end">
      <button mat-icon-button class="d-flex justify-content-center m-3" (click)="navService.closeEndNav()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-column">
      <div class="menu-items d-flex justify-content-center align-items-center flex-column">
        <a href="javascript:void(0)" name="client-onboard-sidenav" id="client-onboard-sidenav" class="item"
          routerLink="/client-onboarding">Client Onboard</a>
        <a href="javascript:void(0)" name="lease-my-bench-sidenav" id="lease-my-bench-sidenav" class="item"
          routerLink="/lease" (click)="navService.closeEndNav()">Lease My Bench</a>
        <a href="javascript:void(0)" name="about-sidenav" id="about-sidenav" class="item" routerLink="/about"
          (click)="navService.closeEndNav()" [ngClass]="{'active-nav': router.isActive('/about', true)}">About</a>
        <a href="javascript:void(0)" name="contact-sidenav" id="contact-sidenav" class="item" routerLink="/contact"
          (click)="navService.closeEndNav()" [ngClass]="{'active-nav': router.isActive('/contact', true)}">Contact</a>
      </div>
      <div class="d-flex flex-row flex-wrap image-box">
        <a href="https://twitter.com/rapidise_io" name="twitter-icon-sidenav" id="twitter-icon-sidenav"
          target="_blank"><img [src]="imagePath + 'twitter-green.svg'" alt="twitter"></a>
        <a href="https://www.facebook.com/Rapidise.inc/" name="facebook-icon-sidenav" id="facebook-icon-sidenav"
          target="_blank"><img [src]="imagePath + 'facebook-green.svg'" alt="facebook"></a>
        <a href="https://www.instagram.com/rapidise.io/" name="instagram-icon-sidenav" id="instagram-icon-sidenav"
          target="_blank"><img [src]="imagePath + 'instagram-green.svg'" alt="instagram"></a>
        <a href="https://www.linkedin.com/company/rapidise-inc/" name="linkedin-icon-sidenav" id="linkedin-icon-sidenav"
          target="_blank"><img [src]="imagePath + 'linkedin-green.svg'" alt="linkedin"></a>
        <a href="https://www.youtube.com/channel/UC9s8pHQOlJb5hVhUEz2BpRA" name="youtube-icon-sidenav"
          id="youtube-icon-sidenav" target="_blank"><img [src]="imagePath + 'youtube-green.svg'" alt="youtube"></a>
      </div>
    </div>
  </mat-sidenav>

  <router-outlet></router-outlet>
  <app-scroll-to-top></app-scroll-to-top>

</mat-sidenav-container>
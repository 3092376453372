<main class="frontpage contact-us">
  <section class="banner w-100 linear-bg">
    <div class="container-fluid content w-100">
      <div class="row">
        <div class="col-12">
          <div class="banner-card d-flex justify-content-center align-items-center">
            <h2 class="title">Contact Us</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="get-in-touch w-100 front-section-padding-tb">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="get-in-touch-card">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                  <h2 class="title">Get in Touch</h2>
                  <form id="contact-form" class="contact-form d-flex flex-column" [formGroup]="contactForm"
                    (ngSubmit)="SaveContactDetails()">
                    <div class="contact-field">
                      <input type="text" formControlName="vName" placeholder="Name*" class="input" pattern="[a-zA-Z]+( [a-zA-Z]+)*" required>
                      <mat-error *ngIf="submitted && has.vName.errors">
                        <mat-error *ngIf="has.vName.errors?.required">
                          Name is required
                        </mat-error>
                        <mat-error *ngIf="has.vName.errors?.pattern">
                          Enter valid name
                        </mat-error>
                      </mat-error>
                    </div>
                    <div class="contact-field">
                      <input type="email" formControlName="vEmailId" placeholder="Email*" class="input" required>
                      <mat-error *ngIf="submitted && has.vEmailId.errors">
                        <mat-error *ngIf="has.vEmailId.errors?.required">
                          Email is required
                        </mat-error>
                        <mat-error *ngIf="has.vEmailId.errors?.email">
                          Enter valid email
                        </mat-error>
                      </mat-error>
                    </div>
                    <div class="contact-field">
                      <input type="number" min="0" formControlName="vPhoneNo" placeholder="Phone*" class="input"
                        required>
                      <mat-error *ngIf="submitted && has.vPhoneNo.errors">
                        <mat-error *ngIf="has.vPhoneNo.errors?.required">
                          Phone is required
                        </mat-error>

                      </mat-error>
                    </div>
                    <div class="contact-field textarea-spacing">
                      <textarea formControlName="txMessage" rows="2" cols="" class="input textarea"
                        placeholder="Message*" pattern="[a-zA-Z]+( [a-zA-Z]+)*"></textarea>
                      <mat-error *ngIf="submitted && has.txMessage.errors">
                        <mat-error *ngIf="has.txMessage.errors?.required">
                          Message is required
                        </mat-error>
                        <mat-error *ngIf="has.txMessage.errors?.pattern">
                          Enter valid message
                        </mat-error>
                      </mat-error>
                    </div>
                  </form>
                </div>
                <div>
                  <button type="submit" name="submit" class="form-btn" form="contact-form">Send Message</button>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                  <h2 class="title">Contact Address</h2>
                  <div class="contact-box">
                    <div class="d-flex flex-column flex-sm-row box">
                      <img [src]="imagePath + 'location-green.svg'" alt="location-green" width="42px">
                      <p class="text d-flex align-items-center text-break">1607 South Main Street, Milpitas, CA 95035
                      </p>
                    </div>
                    <div class="d-flex flex-column flex-sm-row box">
                      <img [src]="imagePath + 'email.svg'" alt="email" width="42px">
                      <p class="text d-flex align-items-center text-break">info@rapidise.io</p>
                    </div>
                    <div class="d-flex flex-column flex-sm-row box">
                      <img [src]="imagePath + 'contact.svg'" alt="contact" width="42px">
                      <p class="text d-flex align-items-center text-break">+1 (415) 851-7851</p>
                    </div>
                    <div class="d-flex flex-column">
                      <h2 class="sub-title">Follow Us on</h2>
                      <div class="d-flex flex-row flex-wrap image-box">
                        <a href="https://twitter.com/rapidise_io" target="_blank"><img
                            [src]="imagePath + 'twitter-green.svg'" alt="twitter"></a>
                        <a href="https://www.facebook.com/Rapidise.inc/" target="_blank"><img
                            [src]="imagePath + 'facebook-green.svg'" alt="facebook"></a>
                        <a href="https://www.instagram.com/rapidise.io/" target="_blank"><img
                            [src]="imagePath + 'instagram-green.svg'" alt="instagram"></a>
                        <a href="https://www.linkedin.com/company/rapidise-inc/" target="_blank"><img
                            [src]="imagePath + 'linkedin-green.svg'" alt="linkedin"></a>
                        <a href="https://www.youtube.com/channel/UC9s8pHQOlJb5hVhUEz2BpRA" target="_blank"><img
                            [src]="imagePath + 'youtube-green.svg'" alt="youtube"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <!-- MAP -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-front-footer></app-front-footer>
</main>
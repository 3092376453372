import { Component, OnInit, HostListener } from "@angular/core";

@Component({
  selector: "app-scroll-to-top",
  templateUrl: "./scroll-to-top.component.html",
})
export class ScrollToTopComponent implements OnInit {
  isShow!: boolean;
  topPosToStartShowing = 200;

  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { RequirementDetailsService } from "./requirement-details.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "./../config/notification.service";
import { environment } from "./../../environments/environment";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { TranslateFormService } from "../utils/services/translate-form.service";

export interface Skill {
  id: string;
  name: string;
}

@Component({
  selector: 'app-requirement-details',
  templateUrl: './requirement-details.component.html'
})
export class RequirementDetailsComponent implements OnInit {

  imagePath = environment.image_path;
  requirementDetailsForm!: FormGroup;
  filterValue: any = '';
  question: any = 1;
  defaultNumber!:any;

  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;
  fourthFormGroup!: FormGroup;

  yearsOfExperience!: string;
  experienceArray: any = [
    { id: 0, name: "Junior (0-2 Years)" },
    { id: 1, name: "Senior (2-8 Years)" },
    { id: 2, name: "Expert (8+ Years)" },
  ];

  filteredSkills: any;
  filteredTalents: any;

  // chip variables
  swapIndex!: any;
  iconVisible = false;
  selectable = true;
  removable = true;
  AddOnBlur = true;
  editRequirmentNameSubmitted: any = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  skills: any = [];
  talents: any = [];
  allSkills: any = [];
  allTalents: any = [];
  allRequirements: any = [];
  displayFlag:boolean=false;

  @ViewChild("skillInput") skillInput!: ElementRef<HTMLInputElement>;
  @ViewChild("talentInput") talentInput!: ElementRef<HTMLInputElement>;

  requirementList!: any;
  editReqId: any;
  userId:any;
  userID:any;

  constructor(private requirementService: RequirementDetailsService,
    private router: Router,
    private notifyService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private tf:TranslateFormService,
    private _formBuilder: FormBuilder) {
    this.filteredTalents = this.allTalentData;
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      talent: [""],
      talentcount: ["", [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
    });
    this.secondFormGroup = this._formBuilder.group({
      skill: [[]],
      skillCount: ["", [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
    });
    this.thirdFormGroup = this._formBuilder.group({
      yearsOfExperience: ["", Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      talentNumberRequired: ["", [Validators.required, Validators.pattern("^[1-9]+$"), Validators.maxLength(100)]],
    });

    this.userID = this.activatedRoute.snapshot.paramMap.get("userId");
    this.userID=this.userID.replace('1EAC09', '/');

    this.userId = this.tf.dynamicPop(this.userID);


    this.getPrimarySkillList();
  }

  DisplayFnTalent(talent: any): string {
    return talent && talent.name ? talent.name : "";
  }

  viewQuestion1() {
    this.question = "1";
  }

  viewQuestion2() {
    this.question = "2";
  }

  async viewQuestion3() {
    this.secondFormGroup.controls['skillCount'].setValue(this.selectedSkill.length);
    if(this.secondFormGroup.controls['skillCount'].value>0){
      this.question = "3";
    }else{
      return;
    }
    
  }

  temp(){
    this.selectedSkill=[];
    this.allSkills.map((obj:any)=>{
      if(obj.checked){
        this.selectedSkill.push(obj.id);
      }
    });
  }

  viewQuestion4() {
    this.question = "4";
  }

  viewQuestion5() {
    this.question = "5";
  }

  allTalentData: any = [];
  getPrimarySkillList() {
    this.requirementService.GetAllPrimarySkills().then((result: any) => {
      if (result.code == 200) {
        for (let i = 0; i < result["data"].length; i++) {
          this.allTalentData.push({ id: result["data"][i].iPrimarySkillId, name: result["data"][i].vPrimarySkill });
        }
        this.allTalents = [];
        let tempTalents = result["data"];
        tempTalents.sort((a: any, b: any) => (a.iPrimarySkillId > b.iPrimarySkillId ? 1 : -1));
        let length=tempTalents.length<15 ? tempTalents.length:15;
        for (let i = 0; i < length; i++) {
          this.allTalents.push({ id: tempTalents[i].iPrimarySkillId, name: tempTalents[i].vPrimarySkill });
        }
      }
    });
  }

   // Chip methods

  // Add skill
  AddSkill(event: any): void {
    const value = event.option.value.name;
    const id = event.option.value.id;
    
    var newskill = 0;
    // Add our skill
    if (value) {
      if (this.allSkills.length > 0) {
        this.allSkills.forEach((element: any) => {
          if (id == element.id) {
            return;
          } else {
            newskill++;
          }
        });
        if (newskill === this.allSkills.length) {
          this.allSkills.push({ name: value.trim(), id: id });
        }
      } else {
        this.allSkills.push({ name: value.trim(), id: id });
      }
    }
    this.secondFormGroup.controls["skill"].setValue("");
    newskill = 0;
  }

  DisplayFnSkill(skill: Skill): string {
    return skill && skill.name ? skill.name : "";
  }

  _filterTalent(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filteredTalents = this.allTalentData;
    this.filteredTalents = this.filteredTalents.filter((talent: { name: string }) => talent.name.toLowerCase().startsWith(filterValue));
  }

  _filterSkill(name: string): any {
    const filterValue = name?.toLowerCase();
    this.filteredSkills = this.allSkillsData;
    this.filteredSkills = this.filteredSkills.filter((skill: { name: string }) => skill.name.toLowerCase().startsWith(filterValue));
  }

  // Add skill to the skills and remove from the allSkills
  Add(skill: any): void {
    const value = skill.name;
    const id = skill.id;

    var newskill = 0;
    // Add our skill
    if (value) {
      if (this.skills.length > 0) {
        this.skills.forEach((element: any) => {
          if (id == element.id) {
            return;
          } else {
            newskill++;
          }
        });
        if (newskill === this.skills.length) {
          this.skills.push({ name: value.trim(), id: id });
          const index = this.allSkills.indexOf(skill);

          if (index >= 0) {
            this.allSkills.splice(index, 1);
          }
        }
      } else {
        this.skills.push({ name: value.trim(), id: id });
        const index = this.allSkills.indexOf(skill);

        if (index >= 0) {
          this.allSkills.splice(index, 1);
        }
      }
    }
    newskill = 0;
  }

  // Add talent
  AddTalent(talent: any): void {
    const currentItemIndex = this.talents.findIndex((obj: any) => obj.id === talent.id);
    // console.log(currentItemIndex, "index >>>>>>>>>>>>>>");
    if (currentItemIndex < 0) {
      // console.log(talent, "talent >>>>>>>>>>>>>>");
      // console.log(this.allTalents, "this.allTalents >>>>>>>>>>>>");
      
      const index = this.allTalents.findIndex((obj: any) => obj.id === talent.id);
      // console.log(index, "index >>>>>>>>>>>>>>");
      if (index >= 0) {
        this.allTalents.splice(index, 1);
      }
      
      if (this.talents.length > 0) {
        this.allTalents.push(this.talents[0]);
        this.talents = [];
      }
  
      const value = talent.name;
      const id = talent.id;
      // Add our talent
      if (value) {
        this.talents.push({ name: value.trim(), id: id });
        const index = this.allTalents.indexOf(talent);
        if (index >= 0) {
          this.allTalents.splice(index, 1);
        }
      }
      this.firstFormGroup.controls["talent"].setValue(id);
  
      this.getAllSkillList();
    }
  }

  allSkillsData: any = [];
  getAllSkillList() {
    this.requirementService.GetAllSkills(this.firstFormGroup.controls.talent.value).then((result: any) => {
      if (result.code == 200) {
        this.allSkillsData = [];
        
        for (let i = 0; i < result["data"].length; i++) {
          this.allSkillsData.push({ id: result["data"][i].iSkillId, name: result["data"][i].MasterSecondarySkill,checked:false });
          // console.log(this.allSkillsData,'skillll>>>>>>')
        }
        this.allSkills = [];
        let tempSkills = result["data"];
        tempSkills.sort((a: any, b: any) => (a.iSkillId > b.iSkillId ? 1 : -1));
        let length=tempSkills.length<15 ? tempSkills.length:15;
        for (let i = 0; i < length; i++) {
          this.allSkills.push({ id: tempSkills[i].iSkillId, name: tempSkills[i].MasterSecondarySkill,checked:false });
          
        }
      }
    });
    // this.requirementService.GetAllSkills(this.firstFormGroup.controls.talent.value).then((result: any) => {
    //   if (result.code == 200) {
    //     this.chipId = result.data;
    //   }
    // });
  }

  selectedSkill: any = [];
  talentsList:any=[];
  isRequirementFullfilled!:any;
  GetStarted() {

    let data = {
      currentUserId:this.userId.iUserId,
      iPrimarySkillId: this.talents[0].id,
      iSkills: this.selectedSkill,
      iExperienceLevel: this.thirdFormGroup.value.yearsOfExperience,
      iTalentNumber: this.fourthFormGroup.value.talentNumberRequired,
    };


    let json = this.tf.staticPush(JSON.stringify(data)); 

    let reqJson={
      data:json
    }
    
    this.requirementService.createRequirement(reqJson).then((result: any) => {
      if (result.code == 200) {
        let params={
          iExperienceLevel:result.data.iExperienceLevel,
          iPrimarySkillId:result.data.iPrimarySkillId
        }
        this.notifyService.showSuccess("Looking for the talents...", "");
        this.requirementService.GetAllEmployees(params).then((res: any) => {
          if (res.code == 200) {
            this.talentsList=res.data.talentData;
            this.isRequirementFullfilled=res.data.isRequirementFullfilled;
            this.talentsList.forEach((element: any) => {
              if (element.txBio.length > 175) {
                element.txBio = element.txBio.substr(0, 175);
              }
            });
            this.question = "5";
          }
        });
      }
    });
  }

  checkInput(){
    if(this.defaultNumber>200){
      return false;
    }else{
      return true;
    }
  }


  More(){
    this.notifyService.showWarning("Please activate your account to view more", "");
  }

  ActivateNow(){
    this.notifyService.showWarning("Please login through the activation link sent to your registered email id", "");
    this.router.navigate([""]);
  }

  // GetAllRequirement() {
  //   this.requirementService.GetAllEmployees().then((result: any) => {
  //     if (result.code == 200) {
  //       this.allRequirements = result["data"];
  //         this.displayFlag=true;
  //     }
  //   });
  // }

}

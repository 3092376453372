import { Component, OnInit } from "@angular/core";
import { AppService } from "./app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "angular-role-app";

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.CreateRequiredHeadTags();
  }

  CreateRequiredHeadTags() {
    // this.appService.CreateScriptTagsForCookiebot();
    this.appService.CreateLinkTagsForGoogleFont();
    this.appService.CreateScriptTagForAmplitude();
    this.appService.CreateScriptTagForZendesk();
    this.appService.CreateScriptTagForGoogleTag();
  }
}

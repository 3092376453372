import { Component, OnInit } from "@angular/core";
import { environment } from "./../../environments/environment";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
})
export class FaqComponent implements OnInit {
  imagePath = environment.image_path;

  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  panelOpenState5 = false;
  panelOpenState6 = false;

  constructor() {}

  ngOnInit(): void {}
}

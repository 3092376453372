import { Injectable } from "@angular/core";
import { init, setSessionId, reset } from "@amplitude/analytics-browser";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export default class Amplitude {
  public Initialize() {
    // initialize amplitude
    init(environment.amplitude.api_key, "", {
      trackingOptions: {
        deviceManufacturer: true,
        deviceModel: true,
        ipAddress: true,
        language: true,
        osName: true,
        osVersion: true,
        platform: true,
      },
      attribution: {
        trackPageViews: true,
      },
    });

    // set session id
    setSessionId(Date.now());

    return true;
  }

  public Reset() {
    // reset amplitude
    reset();

    return true;
  }
}

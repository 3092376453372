import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AUTO_STYLE, animate, state, style, transition, trigger } from "@angular/animations";
import { NavService } from "../nav.service";
import { Router } from "@angular/router";
import { FrontHeaderService } from "./front-header.service";
import { EventEmitterService } from "../../config/event-emitter.service";
import { environment } from "../../../environments/environment";
import Amplitude from "../../utils/amplitude";

const DEFAULT_DURATION = 300;

@Component({
  selector: "app-front-header",
  templateUrl: "./front-header.component.html",
  styles: [],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class FrontHeaderComponent implements OnInit {
  imagePath = environment.image_path;

  @ViewChild("sideNavEnd") sideNavEnd?: ElementRef;

  user: any;

  constructor(public navService: NavService, public router: Router, private frontHeaderService: FrontHeaderService, public eventEmitterService: EventEmitterService, private amplitude: Amplitude) {
    this.amplitude.Initialize();
  }

  ngAfterViewInit() {
    this.navService.sideNavEnd = this.sideNavEnd;
  }

  ngOnInit(): void {
    let user = localStorage.getItem("partner_account_type");
    let accessToken = localStorage.getItem("partner_access_token");
    if (user && accessToken) {
      this.loginUserInformation(user, accessToken);
    }
  }

  public json_user_data = {
    bIsEmailVerified: false,
    bIsProfileCompleted: false,
    bIsQuestionnaireCompleted: false,
    iUserId: 8,
    tiAccountStatus: 0,
    tiAccountType: 3,
    txAccessToken: "3312121541949a21ae31adf7dedd1677",
    vEmailId: "temp-mail@teksun.com",
    vFirstName: "NA",
    vLastName: "NA",
    login_status: false,
    dis_short_name: "NA",
    vProfilePhoto: "",
  };

  loginUserInformation(user: any, accessToken: any) {
    this.frontHeaderService.loginUserInformation({ tiAccountType: user, accessToken: accessToken }).subscribe((res: any) => {
      if (res.code == 200) {
        this.json_user_data = res.data;
        this.json_user_data.login_status = true;
      } else {
        this.router.navigate(["/"]);
      }
    });
  }

  logout() {
    this.frontHeaderService.logoutAPI({}).subscribe((res: any) => {
      if (res.code == 200) {
        this.json_user_data.login_status = false;
        this.frontHeaderService.Logout();
        this.amplitude.Reset();
        this.router.navigate(["/"]);
      } else {
        this.json_user_data.login_status = false;
        this.frontHeaderService.Logout();
        this.amplitude.Reset();
        this.router.navigate(["/"]);
      }
    });
  }

  login(user: any) {
    if (user == "partner") {
      window.location.href = environment.partner_login_page;
    } else if (user == "client") {
      window.location.href = environment.client_login_page;
    } else {
      window.location.href = environment.emp_login_page;
    }
  }
}

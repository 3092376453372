import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RequirementDetailsService {

  constructor(private http: HttpClient) {}

  GetAllPrimarySkills() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/get-all-primary-skill`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllSkills(skillId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/client/v1/get-secondary-skill/` + skillId;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  createRequirement(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/requirement`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllEmployees(params: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/requirement?iExperienceLevel=${params.iExperienceLevel}&iPrimarySkillId=${params.iPrimarySkillId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
